import { DEFAULT_SERVICE_ID, SESSION_KEY_SERVICE } from '../constants'
import { IServiceEntity } from '../shared/interfaces'

const useReturnButton = () => {
  const storedService = sessionStorage.getItem(SESSION_KEY_SERVICE)

  if (storedService) {
    const service: IServiceEntity = JSON.parse(storedService)

    if (service.id === DEFAULT_SERVICE_ID) return undefined

    if (!service.returnUrl) return undefined

    console.log(service.returnUrl)

    return {
      label: service.name,
      href: service.returnUrl.url,
      service: service.id,
    }
  }

  return undefined
}

export default useReturnButton
