import { FunctionComponent } from 'react'

import ErrorMessage from '../ErrorMessage'
import FieldLabel from '../FieldLabel'
import Text from '../Text'
import IInput from './Input.interface'
import { Container, StyledInput, StyledTextarea } from './Input.style'

const Input: FunctionComponent<IInput> = ({
  type,
  label,
  hasError,
  errorMessage,
  disabled,
  placeholder,
  helpText,
  helpLink,
  isRequired,
  width = 'fluid',
  ...rest
}) => {
  const helper = () => {
    if (helpLink) {
      return (
        <Text size="small" as="small" className="bfsso-input-help-text">
          <a href={helpLink} target="_blank" rel="noreferrer">
            {helpText}
          </a>
        </Text>
      )
    }

    return (
      <Text size="small" as="small" className="bfsso-input-help-text">
        {helpText}
      </Text>
    )
  }

  return (
    <Container className={`bfsso-input ${width}`}>
      <FieldLabel>{label}</FieldLabel>
      {type === 'textarea' ? (
        <StyledTextarea
          disabled={disabled}
          placeholder={placeholder}
          required={isRequired}
          className={width}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(rest as any)}
        />
      ) : (
        <StyledInput
          type={type}
          hasError={hasError}
          disabled={disabled}
          placeholder={placeholder}
          required={isRequired}
          className={width}
          {...rest}
        />
      )}
      {helper()}
      {hasError && !!errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : undefined}
    </Container>
  )
}

export default Input
