import styled from 'styled-components'

import { Container } from '../../assets/styles/GlobalStyles'
import { Breakpoint, ColorEnum, FontWeight, Spacing } from '../../shared/enums'

export const FooterContainer = styled(Container)`
  // Text color for the whole component except the logo which is styled itself
  color: ${ColorEnum.bfBlue};
  padding: ${Spacing.ExtraLarge};

  a {
    text-decoration: none;
  }

  @media (max-width: ${Breakpoint.Large}) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: ${Spacing.Large};
  }
`

export const VerticalDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${ColorEnum.bfBlue};

  @media (max-width: ${Breakpoint.Large}) {
    display: none;
  }
`

export const Section = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: ${Breakpoint.Large}) {
    padding-left: 0px;
    margin-top: 20px;
    &:first-child {
      margin-bottom: 20px;
    }
  }
`

export const SectionTitle = styled.div`
  font-weight: ${FontWeight.Bold};
`

export const SectionText = styled.div`
  font-weight: ${FontWeight.Thin};
`

export const SectionLink = styled.div`
  font-weight: ${FontWeight.Thin};
  margin: 4px 0px 4px 0px;
`
