import tokens from '../api/tokens'
import { setSpinner } from '../redux/slices/spinnerSlice'
import store from '../redux/store'

/**
 * Destroys session and redirect user to given return url.
 * @param returnUrl
 */
const logoutAndRedirect = async (returnUrl = '/logout') => {
  store.dispatch(setSpinner({ visible: true }))
  const response = await tokens.logOut()

  let timeoutId: string | number | NodeJS.Timeout | undefined

  const iframe = document.createElement('iframe')
  iframe.hidden = true

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLoad = (_event: any) => {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      window.location.assign(returnUrl)
      store.dispatch(setSpinner({ visible: false }))
    }, 3000)
  }

  iframe.addEventListener('load', handleLoad)
  iframe.src = response?.logoutUrl
  document.body.append(iframe)

  // Set an initial timeout to trigger logout if the iframe doesn't load within 10 seconds
  timeoutId = setTimeout(() => {
    window.location.assign(returnUrl)
    store.dispatch(setSpinner({ visible: false }))
  }, 10_000)
}

export default logoutAndRedirect
