import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ReactComponent as LogoSvg } from '../../assets/logos/logo.svg'
import { colorPropToHex } from '../../utils/style-utils'
import ILogo, { LogoHeight, LogoWidth } from './Logo.interface'

const StyledLogo = styled(LogoSvg)`
  width: ${LogoWidth.Medium};
  height: ${LogoHeight.Medium};

  &.bfsso-logo.large {
    width: ${LogoWidth.Large};
    height: ${LogoHeight.Large};
  }
`

const Logo: FunctionComponent<ILogo> = ({ color = 'black', size = 'medium', ariaLabel }) => {
  const fillColor = colorPropToHex(color)

  return (
    <StyledLogo
      className={`bfsso-logo ${size || undefined}`}
      aria-label={ariaLabel}
      fill={fillColor}
    />
  )
}

export default Logo
