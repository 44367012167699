import { FunctionComponent } from 'react'
import styled from 'styled-components'

import StepButton from '../../../../../components/Stepper/StepButton'
import { Spacing } from '../../../../../shared/enums'

type Props = {
  currentStep: number
  numberOfSteps: number
}

const WeakIdentificationStepperWrapper = styled.div`
  padding-top: ${Spacing.Large};
  padding-bottom: ${Spacing.Large};
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: center;

  @media (min-width: 768px) {
    gap: 4rem;
  }
`

const WeakIdentificationStepper: FunctionComponent<Props> = props => {
  const { currentStep, numberOfSteps } = props
  const numberOfStepsArray = Array.from({ length: numberOfSteps }, (_, i) => i + 1)

  return (
    <WeakIdentificationStepperWrapper>
      {numberOfStepsArray.map(step => (
        <StepButton key={step} index={step} active={step === currentStep} />
      ))}
    </WeakIdentificationStepperWrapper>
  )
}

export { WeakIdentificationStepper }
