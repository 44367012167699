import { Content, Page } from '../assets/styles/GlobalStyles'
import Footer from './Footer/Footer'
import { Spinner } from './Spinner/Spinner'

type LayoutProps = {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <Page>
      <Content>
        <Spinner />
        {children}
      </Content>
      <Footer />
    </Page>
  )
}

export default Layout
