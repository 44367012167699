import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ColorEnum } from '../../shared/enums'
import ISelect from './Select.interface'

const SelectContainer = styled.div`
  position: relative;
  margin-bottom: 0.5rem;

  &.fluid {
    width: 100%;
  }

  &.fixed {
    width: fit-content !important;
  }
`

const StyledSelect = styled.select<{ hasError?: boolean }>`
  border: 1px solid ${props => (props.hasError ? ColorEnum.bfRed : ColorEnum.bfBlue)};
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: ${props => (props.hasError ? ColorEnum.bfPink : ColorEnum.white)};
  text-transform: none;

  padding-right: 3rem;

  &.fluid {
    width: 100%;
  }

  &.fixed {
    width: auto;
  }

  &:focus {
    transition: border 0.25s ease;
    border: 1px solid ${ColorEnum.black};
    background-color: ${ColorEnum.white};
  }
`

const Arrow = styled.div`
  color: ${ColorEnum.bfBlue};
  pointer-events: none;
  position: absolute;
  width: 3rem;
  height: 3rem;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Select: FunctionComponent<ISelect> = ({ children, width = 'fluid', ...rest }) => {
  return (
    <SelectContainer className={`bfsso-select ${width}`}>
      <StyledSelect className={width} {...rest}>
        {children}
      </StyledSelect>
      <Arrow aria-hidden="true">&#9660;</Arrow>
    </SelectContainer>
  )
}

export default Select
