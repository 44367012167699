import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Icon from '../../components/Icon'
import Text from '../../components/Text'
import { ColorEnum } from '../../shared/enums'
import HeaderContainer from '../HeaderContainer'

const Wrapper = styled.div`
  max-width: 960px;
  width: 100%;
  margin: auto;
  padding: 3rem;
  margin-bottom: 2rem;
  display: flex;

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;

  @media (max-width: 960px) {
    padding: 0 0 1.5rem 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
`

const Heading = styled.h2`
  font-size: 32px;
  line-height: 48px;
  text-transform: none;
  margin-bottom: 0.5rem;

  & span {
    font-weight: normal;
  }
`

const Button = styled.a`
  text-decoration: none;
  padding: 0 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  color: white;
  background-color: ${ColorEnum.bfBlue};
  border-radius: 25px;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background-color: ${ColorEnum.white};
    color: ${ColorEnum.bfBlue};
    border: 1px solid ${ColorEnum.bfBlue};
  }
`

const StrongAuthRequired = (props: { onStartAuth: () => void }) => {
  const { t } = useTranslation()

  return (
    <>
      <HeaderContainer />
      <div>
        <Wrapper>
          <IconWrapper>
            <Icon name="warning" color="bf-blue" size="large" />
          </IconWrapper>
          <ContentWrapper>
            <div>
              <Heading>
                {t('strong-auth-required.title')} <br />
              </Heading>
              <Text size="large" color="bf-blue">
                {t('strong-auth-required.description')}
              </Text>
            </div>
            <Button onClick={props.onStartAuth}>{t('strong-auth-required.button-label')}</Button>
          </ContentWrapper>
        </Wrapper>
      </div>
    </>
  )
}

export default StrongAuthRequired
