import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Heading from '../../../components/Heading'
import Spacer from '../../../components/Spacer'
import PortalRouteWrapper from '../../../containers/PortalRouteWrapper'
import useL3Access from '../../../hooks/useL3Access'
import { RootState } from '../../../redux/store'
import INotifications from './Notifications.interface'
import Received from './Received'
import Sent from './Sent'

const mapStateToProps = (state: RootState) => {
  return {
    notifications: state.me.notifications,
  }
}

const Notifications: FunctionComponent<INotifications> = ({ notifications }) => {
  const { t: translate } = useTranslation()
  const hasL3Access = useL3Access()

  const title = translate('requests.title')
  const titleWithRequestN = !notifications ? title : `${title} (${notifications.length})`

  return (
    <PortalRouteWrapper title={titleWithRequestN}>
      <Heading level="h1" visualLevel="h4" color="bf-blue">
        {translate('requests.title')}
      </Heading>

      {!hasL3Access ? <div>{translate('requests.modify-l3access')}</div> : null}

      <Received />

      <Spacer size="small" />

      <Sent />
    </PortalRouteWrapper>
  )
}

// eslint-disable-next-line react-redux/prefer-separate-component-file
export default connect(mapStateToProps)(Notifications)
