import styled from 'styled-components'

import { FontWeight } from '../shared/enums'

const ErrorMessage = styled.span`
  display: block;
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
`

export default ErrorMessage
