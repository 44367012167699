import styled from 'styled-components'

import { ColorEnum } from '../../shared/enums'

export const Container = styled.div`
  max-width: 36.625rem;

  & .bfsso-input-help-text {
    display: block;
    margin-top: calc(-0.5rem + 5px);
    margin-bottom: 0.5rem;
  }

  &.fluid {
    width: 100%;
  }

  &.fixed {
    width: fit-content !important;
  }
`

export const StyledInput = styled.input<{ hasError?: boolean }>`
  border: 1px solid ${props => (props.hasError ? ColorEnum.bfRed : ColorEnum.bfBlue)};
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: ${props => (props.hasError ? ColorEnum.bfPink : ColorEnum.white)};
  text-transform: none;
  margin-bottom: 0.5rem;

  &::placeholder {
    color: ${ColorEnum.bfDarkGray};
  }

  &:focus {
    transition: border 0.25s ease;
    border: 1px solid ${ColorEnum.black};
    background-color: ${ColorEnum.white};
  }

  &:disabled {
    background-color: ${ColorEnum.bfLightGray};
    border: 1px solid #8c8c8c;
    color: #8c8c8c;
  }

  &:read-only {
    background-color: ${ColorEnum.bfLightGray};
    border: 1px solid #8c8c8c;
  }

  &.fluid {
    width: 100%;
  }

  &.fixed {
    width: auto;
  }
`

export const StyledTextarea = styled.textarea<{ hasError?: boolean }>`
  border: 1px solid ${props => (props.hasError ? ColorEnum.bfRed : ColorEnum.bfBlue)};
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: ${props => (props.hasError ? ColorEnum.bfPink : ColorEnum.white)};
  text-transform: none;
  margin-bottom: 0.5rem;

  &::placeholder {
    color: ${ColorEnum.bfDarkGray};
  }

  &:focus {
    transition: border 0.25s ease;
    border: 1px solid ${ColorEnum.black};
    background-color: ${ColorEnum.white};
  }

  &:disabled {
    background-color: ${ColorEnum.bfLightGray};
    border: 1px solid #8c8c8c;
    color: #8c8c8c;
  }

  &:read-only {
    background-color: ${ColorEnum.bfLightGray};
    border: 1px solid #8c8c8c;
  }

  &.fluid {
    width: 100%;
  }

  &.fixed {
    width: auto;
  }
`
