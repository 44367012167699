import { FunctionComponent } from 'react'

import { colorPropToHex } from '../../utils/style-utils'
import IHeading from './Heading.interface'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  StyledHeading,
} from './Heading.style'

const headingVariants = {
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
  h5: Heading5,
  h6: Heading6,
}

const Heading: FunctionComponent<IHeading> = ({
  level,
  visualLevel,
  color = 'black',
  children,
}) => {
  const Component = headingVariants[visualLevel ?? level] as StyledHeading
  const textColor = colorPropToHex(color)

  return (
    <Component as={level} className="bfsso-heading" style={{ color: textColor }}>
      {children}
    </Component>
  )
}

export default Heading
