import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectSpinner } from '../../redux/slices/spinnerSlice'
import { LdsRoller, SpinnerContainer, SpinnerWrapper } from './Spinner.style'

export const Spinner = () => {
  const spinnerVisible = useSelector(selectSpinner)

  const { t } = useTranslation()

  return (
    <SpinnerWrapper visible={spinnerVisible}>
      <SpinnerContainer>
        <p>{t('microcopies.wait')}</p>
        <LdsRoller>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LdsRoller>
      </SpinnerContainer>
    </SpinnerWrapper>
  )
}
