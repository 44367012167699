/* eslint-disable react/jsx-no-bind */

/* eslint-disable padding-line-between-statements */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormApi } from 'final-form'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import me from '../../../../api/me'
import Button from '../../../../components/Button/Button'
import CenteredLoadingIndicator from '../../../../components/CenteredLoadingIndicator/CenteredLoadingIndicator.component'
import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import Input from '../../../../components/Input'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import { QUERY_KEY_USER_PROFILE } from '../../../../constants'
import EmailVerificationModal from '../../../../containers/EmailVerificationModal'
import FormContainer from '../../../../containers/FormContainer'
import useL3Access from '../../../../hooks/useL3Access'
import useValidators from '../../../../hooks/useValidators'
import { setSpinner } from '../../../../redux/slices/spinnerSlice'
import { IUserInformation } from '../../../../shared/interfaces'
import { FormValues } from '../../../../shared/types'

type Props = {
  data: IUserInformation | undefined
}

/**
 * This is obsolete. Let's remove this when tested and confirmed.
 * @param param0
 * @returns
 */
const ChangeEmailFormObsolete: FunctionComponent<Props> = ({ data }) => {
  const { t } = useTranslation()

  const [externalValidationErrors, setExternalValidationErrors] = useState()
  const { isEmail, isEmailTaken, required } = useValidators()
  const [userEmail, setUserEmail] = useState('')
  const [emailError, setEmailError] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const hasL3Access = useL3Access()

  const dispatch = useDispatch()

  const queryClient = useQueryClient()

  const formRef: React.MutableRefObject<FormApi | null> = useRef(null)
  const resetForm = () => formRef?.current?.reset()

  const changeEmailMutation = useMutation({
    mutationFn: (values: FormValues) => me.changeEmail(values),
  })

  //eslint-disable-next-line unicorn/consistent-function-scoping, @typescript-eslint/no-unused-vars
  const onSubmit = async (values: FormValues) => {
    if (typeof values['email'] !== 'string') {
      return
    }
    const emailValidation = await isEmailTaken(values['email'])
    if (emailValidation) {
      setEmailError(emailValidation)
      return
    }
    changeEmailMutation.mutate(values)
  }

  const handleOnAfterEmailVerification = async (vKey: string) => {
    const response = await me.changeEmail({ email: userEmail, verificationKey: vKey })
    if (response.message === 'Success') {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_USER_PROFILE] })
      resetForm()
      setExternalValidationErrors(undefined)
      dispatch(openSnackbar(t('snackbar.email-update-success'), false))
      setIsModalOpen(false)
      dispatch(setSpinner({ visible: false }))
    }
  }

  const handleUserEmailChange = (email: string) => {
    setUserEmail(email)
  }

  useEffect(() => {
    // TODO: Server errors
    if (changeEmailMutation.status === 'success' && changeEmailMutation.data.errors) {
      setExternalValidationErrors(changeEmailMutation.data.errors)
    }
  }, [changeEmailMutation.data, changeEmailMutation.status])

  return (
    <Container gap="none" padding="none">
      <Container padding="none" margin="none none small none">
        <Heading level="h2" visualLevel="h5" color="bf-blue">
          {t('user-profile.account')}
        </Heading>
      </Container>

      {!data ? (
        <CenteredLoadingIndicator />
      ) : (
        <>
          <FormContainer
            onSubmit={onSubmit}
            externalValidationErrors={externalValidationErrors}
            formRef={formRef}
          >
            <Input
              type="email"
              name="emailVerification"
              defaultValue={data?.email}
              label={`${t('microcopies.form-label-account-email')}`}
              hasError={!!emailError && userEmail !== data?.email}
              errorMessage={emailError}
              readOnly={!hasL3Access}
              onBlur={e => handleUserEmailChange(e.target.value)}
            />
            {hasL3Access ? (
              <Button
                label={t('user-profile.email-verify-save')}
                disabled={
                  !hasL3Access || !!emailError || userEmail === '' || userEmail === data?.email
                }
                onClick={() => setIsModalOpen(true)}
              />
            ) : null}

            <Field
              name="email"
              type="hidden"
              initialValue={userEmail}
              validators={[required, isEmail]}
            >
              {({ input }) => <input {...input} value={userEmail} />}
            </Field>
          </FormContainer>

          {hasL3Access ? (
            <EmailVerificationModal
              email={userEmail}
              isOpen={isModalOpen}
              onAfterSubmit={handleOnAfterEmailVerification}
              onCancel={() => setIsModalOpen(false)}
            />
          ) : null}
        </>
      )}
    </Container>
  )
}

export default ChangeEmailFormObsolete
