/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import me from '../../../../api/me'
import Button from '../../../../components/Button'
import CenteredLoadingIndicator from '../../../../components/CenteredLoadingIndicator/CenteredLoadingIndicator.component'
import Container from '../../../../components/Container'
import ReactFormContainer from '../../../../components/ReactFormWrapper/ReactFormWrapper.component'
import Spacer from '../../../../components/Spacer'
import Text from '../../../../components/Text'
import {
  BASE_URL,
  DEFAULT_SERVICE,
  DEFAULT_SERVICE_ID,
  QUERY_KEY_USER_ORGANIZATIONS,
  QUERY_KEY_USER_PROFILE,
  SESSION_KEY_REDIRECT,
  SESSION_KEY_SERVICE,
  STORAGE_KEY_LAST_ORGANIZATION,
} from '../../../../constants'
import LoginRouteWrapper from '../../../../containers/LoginRouteWrapper'
import { selectAuthLevel } from '../../../../redux/slices/meSlice'
import { setSpinner } from '../../../../redux/slices/spinnerSlice'
import { PromptValues, RedirectValues } from '../../../../routes/Sso/Sso.schema'
import { authLevelToAcr } from '../../../../utils/auth-utils'
import { SelectOrganizationForm, SelectOrganizationFormType } from './SelectOrganization.schema'

const SERVICE_USER_HEADERS = { 'Content-Type': 'application/json' }

export const handleUserServicePatch = async (serviceId: string, selectedOrganization?: string) => {
  const serviceUserEndpoint = new URL(`/api/services/${serviceId}/serviceuser`, BASE_URL)

  if (!selectedOrganization) {
    return await fetch(serviceUserEndpoint, {
      method: 'PATCH',
      headers: SERVICE_USER_HEADERS,
    })
  }

  return await fetch(serviceUserEndpoint, {
    method: 'PATCH',
    headers: SERVICE_USER_HEADERS,
    body: JSON.stringify({ businessId: selectedOrganization }),
  })
}

const SelectOrganization: FunctionComponent = () => {
  const { t: translate } = useTranslation()
  const navigate = useNavigate()
  const authLevel = useSelector(selectAuthLevel)

  const dispatch = useDispatch()

  const lastUsedOrganization = localStorage.getItem(STORAGE_KEY_LAST_ORGANIZATION) || undefined

  const organizationsQuery = useQuery([QUERY_KEY_USER_ORGANIZATIONS], me.getMyOrganizations)
  const userInfoQuery = useQuery([QUERY_KEY_USER_PROFILE], me.getMyUserInformation)

  const service = JSON.parse(
    sessionStorage.getItem(SESSION_KEY_SERVICE) || JSON.stringify(DEFAULT_SERVICE)
  )

  // Constants
  const translatedTitle = translate('select-organization.title')

  // Here we define a form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<SelectOrganizationFormType>({
    defaultValues: {
      organization: lastUsedOrganization || '',
    },
    resolver: zodResolver(SelectOrganizationForm),
  })

  const directRedirect = async () => {
    const redirectUrl = new URL(service.redirection.url)

    redirectUrl.searchParams.append(RedirectValues.Prompt, PromptValues.None)

    if (authLevel) {
      const acr = authLevelToAcr(authLevel)
      redirectUrl.searchParams.append(RedirectValues.AcrValues, acr)
    }

    if (service.id !== DEFAULT_SERVICE_ID) {
      await handleUserServicePatch(service.id)
    }

    window.location.assign(redirectUrl.toString())
  }

  if (!service?.redirection?.preferences.requireOrgSelection) {
    directRedirect()
  }

  /**
   * Forces the last used organization to be selected if it exists
   */
  useEffect(() => {
    const currentValue = watch('organization')

    if (!lastUsedOrganization && !currentValue) {
      return
    }

    if (lastUsedOrganization && !currentValue) {
      setValue('organization', lastUsedOrganization)
    }
  }, [lastUsedOrganization])

  useEffect(() => {
    if (organizationsQuery.status === 'success' && !!organizationsQuery.data) {
      const lastUsedOrg = organizationsQuery.data.find(
        org => org.businessId === lastUsedOrganization
      )

      if (!lastUsedOrganization) {
        localStorage.removeItem(STORAGE_KEY_LAST_ORGANIZATION)

        return
      }

      if (!lastUsedOrg) {
        localStorage.removeItem(STORAGE_KEY_LAST_ORGANIZATION)

        return
      }

      setValue('organization', lastUsedOrganization)
    }
  }, [organizationsQuery.data, organizationsQuery.status])

  const handleFormSubmit = useCallback(
    async (values: SelectOrganizationFormType) => {
      dispatch(setSpinner({ visible: true }))

      const selectedOrganization = values['organization'] as string

      localStorage.setItem(STORAGE_KEY_LAST_ORGANIZATION, selectedOrganization)

      if (service.id) {
        const validationEndpoint = new URL(`/api/services/${service.id}/redirect`, BASE_URL)

        if (service.redirection.url) {
          const redirectUrl = new URL(service.redirection.url)

          if (values['organization'])
            redirectUrl.searchParams.append(RedirectValues.SelectedOrg, selectedOrganization)

          redirectUrl.searchParams.append(RedirectValues.Prompt, PromptValues.None)

          if (authLevel) {
            const acr = authLevelToAcr(authLevel)
            redirectUrl.searchParams.append(RedirectValues.AcrValues, acr)
          }

          validationEndpoint.searchParams.append(RedirectValues.RedirectUrl, redirectUrl.toString())
        }

        const res = await fetch(validationEndpoint.toString())

        if (service.id !== DEFAULT_SERVICE_ID) {
          await handleUserServicePatch(service.id, selectedOrganization)
        }

        const data = await res.json()

        if (data.redirectUrl) window.location.assign(data.redirectUrl)
      } else {
        navigate('/portal')
      }

      sessionStorage.removeItem(SESSION_KEY_REDIRECT)
    },
    [authLevel, navigate, service.id, service.redirection.url]
  )

  const skipSelection = async () => {
    if (service.id) {
      const validationEndpoint = new URL(`/api/services/${service.id}/redirect`, BASE_URL)

      if (service.redirection.url) {
        const redirectUrl = new URL(service.redirection.url)

        validationEndpoint.searchParams.append(RedirectValues.RedirectUrl, redirectUrl.toString())
      }

      const res = await fetch(validationEndpoint.toString())
      const data = await res.json()

      await handleUserServicePatch(service.id)

      if (data.redirectUrl) window.location.assign(data.redirectUrl)
    } else {
      navigate('/portal')
    }
  }

  const navigateToPortal = () => {
    return navigate('/portal/organization')
  }

  if (
    organizationsQuery.isLoading ||
    userInfoQuery.isLoading ||
    !service?.redirection?.preferences.requireOrgSelection
  ) {
    return <CenteredLoadingIndicator />
  }

  if (organizationsQuery?.data && organizationsQuery?.data?.length) {
    return (
      <LoginRouteWrapper title={translatedTitle}>
        <Container alignItems="center" gap="small" textAlign="center">
          <Text as="p">
            {translate('select-organization.ingress', {
              firstName: userInfoQuery.data?.firstname ?? '',
            })}
          </Text>
          <Text size="extra-large" weight="bold">
            {service.redirection?.name ?? service.name}
          </Text>
          <ReactFormContainer onSubmit={handleSubmit(handleFormSubmit)}>
            <label htmlFor={'organization'}>{translate('select-organization.title')}</label>
            <select {...register('organization')} id="organization">
              {organizationsQuery.data?.map(org => {
                return (
                  <option key={org.name} value={org.businessId}>
                    {org.name}
                  </option>
                )
              })}
            </select>
            {errors.organization ? (
              <span className="error-message">{errors.organization.message}</span>
            ) : null}
            <Spacer size={'large'} />

            <Container flexDirection="row" justifyContent="center" padding="none">
              <Button
                label={translate('select-organization.use-this-organization')}
                type="submit"
              />
            </Container>
          </ReactFormContainer>
        </Container>
      </LoginRouteWrapper>
    )
  }

  return (
    <LoginRouteWrapper title={translatedTitle}>
      <Container alignItems="center" gap="small" textAlign="center">
        <Text>{translate('select-organization.no-organizations')}</Text>
        <Container flexDirection="row" alignItems="center" justifyContent="center">
          <Button
            label={translate('select-organization.no-organizations-continue')}
            onClick={navigateToPortal}
            variant="secondary"
          />
          <Button
            label={translate('select-organization.no-organizations-skip')}
            onClick={skipSelection}
            variant="primary"
          />
        </Container>
      </Container>
    </LoginRouteWrapper>
  )
}

export default SelectOrganization
