import { Children, FunctionComponent, createElement, isValidElement } from 'react'

import Checkbox from '../../../components/Checkbox'
import ICheckbox from '../../../components/Checkbox/Checkbox.interface'
import CheckboxList from '../../../components/CheckboxList'
import Radio from '../../../components/Radio'
import IRadio from '../../../components/Radio/Radio.interface'
import RadioGroup from '../../../components/RadioGroup'
import WithField from '../../../hocs/withField'
import IFieldSetContainer from './FieldSetContainer.interface'

const FieldSetContainer: FunctionComponent<IFieldSetContainer> = ({
  type,
  name,
  label,
  isRequired,
  children,
}) => {
  const fieldLabel = isRequired ? `${label} * ` : label

  /**
   * Checkbox list
   */
  if (type === 'checkbox-list') {
    const CheckboxWithField = WithField(Checkbox)

    return (
      <CheckboxList name={name} label={fieldLabel}>
        {Children.map(children, child => {
          return isValidElement<ICheckbox>(child)
            ? createElement(CheckboxWithField, {
                ...child.props,
                label: fieldLabel,
              })
            : undefined
        })}
      </CheckboxList>
    )
  }

  /**
   * Radio group
   */
  if (type === 'radio-group') {
    const RadioWithField = WithField(Radio)

    return (
      <RadioGroup name={name} label={fieldLabel}>
        {Children.map(children, child => {
          return isValidElement<IRadio>(child)
            ? createElement(RadioWithField, {
                ...child.props,
                label: fieldLabel,
              })
            : undefined
        })}
      </RadioGroup>
    )
  }

  // eslint-disable-next-line unicorn/no-null
  return null
}

export default FieldSetContainer
