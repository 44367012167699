import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Button from '../../../../../components/Button'
import CenteredLoadingIndicator from '../../../../../components/CenteredLoadingIndicator/CenteredLoadingIndicator.component'
import Heading from '../../../../../components/Heading'
import Link from '../../../../../components/Link'
import Paragraph from '../../../../../components/Paragraph/Paragraph.component'
import ReactFormContainer from '../../../../../components/ReactFormWrapper/ReactFormWrapper.component'
import Spacer from '../../../../../components/Spacer'
import { setShowSnackbar } from '../../../../../redux/slices/snackbarSlice'
import { setSpinner } from '../../../../../redux/slices/spinnerSlice'
import { Spacing } from '../../../../../shared/enums'
import {
  WeakIdentificationRequestKeys,
  handleSendSupportTicket,
  handleSilentLogout,
  handleWeakIdentificationCheckOrganization,
  handleWeakIdentificationJoinOrganization,
} from '../../WeakIdentification.requests'
import {
  FormInputElementDefaultProps,
  SupportTicketRequestType,
  WeakIdentificationJoinOrganizationStepType,
  WeakIdentificationValidationJoinToOrganizationStep,
} from '../../WeakIdentification.schema'
import { WeakIdentificationUrls } from '../../WeakIdentification.urls'

const JoinOrganizationFormFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: ${Spacing.ExtraLarge};
`

/**
 * JoinToOrganization step form.
 * @param props
 * @returns {FunctionComponent<FormInputElementDefaultProps>}
 */
const JoinToOrganizationForm: FunctionComponent<FormInputElementDefaultProps> = props => {
  const { t, dispatch, service } = props

  // We will use react-form-hooks from now on. This is the first step to convert the form to react-form-hooks.
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<WeakIdentificationJoinOrganizationStepType>({
    defaultValues: {
      businessId: '',
      service,
    },
    resolver: zodResolver(WeakIdentificationValidationJoinToOrganizationStep),
  })
  const { i18n } = useTranslation()

  const navigate = useNavigate()
  const [redirecting, setRedirecting] = useState<boolean>(false)
  const submitRequest = useMutation({
    mutationKey: [WeakIdentificationRequestKeys.joinToOrganization],
    mutationFn: handleWeakIdentificationJoinOrganization,
  })
  const checkBusinessId = useMutation({
    mutationKey: [WeakIdentificationRequestKeys.checkOrganization],
    mutationFn: handleWeakIdentificationCheckOrganization,
  })
  const sendSupportTicket = useMutation({
    mutationKey: [WeakIdentificationRequestKeys.supportTicket],
    mutationFn: handleSendSupportTicket,
  })
  const silentLogoutRequest = useQuery({
    queryKey: [WeakIdentificationRequestKeys.silentLogout],
    queryFn: handleSilentLogout,
    enabled: false,
  })

  useEffect(() => {
    dispatch(
      setSpinner({
        visible:
          checkBusinessId.isLoading || submitRequest.isLoading || sendSupportTicket.isLoading,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBusinessId.isLoading, submitRequest.isLoading, sendSupportTicket.isLoading])

  useEffect(() => {
    if (!submitRequest.data) {
      return undefined
    }

    if (!submitRequest?.data?.redirectUrl) {
      return undefined
    }

    silentLogoutRequest.refetch()
    window.location.assign(submitRequest.data.redirectUrl)

    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitRequest.data])

  /**
   * This function sends a body with businessId to the API and if it's successful it will navigate the user to the next step.
   * @param data
   */
  const handleJoinToOrganization = (data: WeakIdentificationJoinOrganizationStepType) => {
    setRedirecting(true)
    submitRequest.mutate(data, {
      onSuccess: response => {
        if (response.redirectUrl) {
          dispatch(
            setShowSnackbar({
              error: false,
              showSnackbar: true,
              message: t('weak-identification.join-to-organization-success'),
            })
          )
        }
      },
      onError: error => {
        console.warn(error)
        setRedirecting(false)
        dispatch(
          setShowSnackbar({
            error: true,
            showSnackbar: true,
            message: t('weak-identification.join-to-organization-error'),
          })
        )
      },
    })
  }

  /**
   * This function checks on the background does the user have privileges to join the organization via e-mail and business id.
   * @param businessId
   * @returns
   */
  const handleBusinessIdCheck = (businessId: string) => {
    clearErrors('businessId')
    clearErrors('userHasNoPrivileges')
    checkBusinessId.reset()

    if (businessId.trim().length === 0) {
      return
    }

    const body: WeakIdentificationJoinOrganizationStepType = {
      businessId,
      service: '',
    }
    // Validates the business id check
    const isValidBusinessId = WeakIdentificationValidationJoinToOrganizationStep.safeParse(body)

    // If the business id is not valid we set the errors
    if (!isValidBusinessId.success) {
      setError('businessId', {
        message: t('weak-identification.join-to-organization-invalid-business-id') as string,
      })

      return
    }

    // We call the API to check if the business id is valid or if the user has privileges to join the organization
    return checkBusinessId.mutate(body, {
      onSuccess: data => {
        if (data.hasErrors) {
          if (!data.existsInGlobalCRM) {
            return setError('businessId', {
              message: t('weak-identification.join-to-organization-not-in-crm') as string,
            })
          }

          if (!data.isInCrm) {
            setError('businessId', {
              message: t(
                'weak-identification.join-to-organization-no-privileges-to-join'
              ) as string,
            })
          }

          // Sometimes user's session disappears.
          if (!data.usersDomain) {
            setShowSnackbar({
              error: true,
              showSnackbar: true,
              message: t('weak-identification.session-expired'),
            })
            navigate(WeakIdentificationUrls.emailStep)
          }

          return dispatch(
            setShowSnackbar({
              error: true,
              showSnackbar: true,
              message: t('weak-identification.join-to-organization-no-privileges-to-join'),
            })
          )
        }

        return dispatch(
          setShowSnackbar({
            error: false,
            showSnackbar: true,
            message: t('weak-identification.join-to-organization-organization-privileges-to-join', {
              organizationName: data.organizationName,
            }),
          })
        )
      },
      onError: error => {
        console.warn(error)
        setError('businessId', {
          message: t('weak-identification.join-to-organization-check-organization-error') as string,
        })

        return dispatch(
          setShowSnackbar({
            error: true,
            showSnackbar: true,
            message: t('weak-identification.join-to-organization-check-organization-error'),
          })
        )
      },
    })
  }

  /**
   * This function handles sending of support ticket and redirects user
   * when server response is 200. We don't save business id to session storage
   * when this button is used.
   */
  const handleSupportTicket = async () => {
    setRedirecting(true)
    const url = t('weak-identification.join-to-organization-become-a-customer-link') as string
    const payload: SupportTicketRequestType = {
      businessId: watch('businessId'),
      serviceId: service,
      lang: i18n.language,
    }
    await sendSupportTicket.mutateAsync(payload)
    silentLogoutRequest.refetch()
    window.location.assign(url)
  }

  if (redirecting) {
    return <CenteredLoadingIndicator />
  }

  return (
    <ReactFormContainer
      style={{ marginTop: '2rem', marginBottom: '2rem' }}
      onSubmit={handleSubmit(handleJoinToOrganization)}
    >
      <Heading color={'bf-blue'} level={'h4'}>
        {t('weak-identification.join-to-organization-form-title')}
      </Heading>
      <Paragraph>
        {t('weak-identification.join-to-organization-form-description-row-one')}
        <span>&nbsp;</span>
        <Link
          target="_blank"
          href={t('weak-identification.join-to-organization-check-business-id-link') as string}
        >
          {t('weak-identification.join-to-organization-check-business-id-helper-trigger')}
        </Link>
      </Paragraph>
      <Spacer size={'large'} />
      <label htmlFor={'businessId'}>
        {t('weak-identification.join-to-organization-business-id-label')}
      </label>
      <input
        type={'text'}
        id={'businessId'}
        {...register('businessId')}
        onBlur={e => handleBusinessIdCheck(e.target.value)}
        style={{ borderColor: checkBusinessId.data?.hasErrors ? 'red' : undefined }}
      />
      {errors.businessId ? (
        <span className="error-message">{errors.businessId.message}</span>
      ) : null}

      {errors.businessId ? (
        <div>
          {checkBusinessId?.data?.existsInGlobalCRM ? (
            <>
              <Spacer size={'large'} />
              <Button
                label={t('weak-identification.join-to-organization-become-a-customer')}
                variant={'primary'}
                onClick={() => handleSupportTicket()}
              />
            </>
          ) : null}
        </div>
      ) : null}
      {!checkBusinessId.data?.hasErrors && checkBusinessId.data ? (
        <span className="success-message">
          {t('weak-identification.join-to-organization-organization-privileges-to-join', {
            organizationName: checkBusinessId.data?.organizationName,
          })}
        </span>
      ) : null}
      <JoinOrganizationFormFooter>
        <Button
          disabled={
            checkBusinessId.isLoading ||
            submitRequest.isLoading ||
            checkBusinessId.isError ||
            checkBusinessId?.data?.hasErrors
          }
          label={t('weak-identification.join-to-organization-form-submit')}
          variant={'primary'}
          type={'submit'}
        />
      </JoinOrganizationFormFooter>
    </ReactFormContainer>
  )
}

export { JoinToOrganizationForm }
