import { Dispatch, SetStateAction, useState } from 'react'

import { RECORDS_PER_PAGE } from '../constants'

function usePagination<T>(data: Array<T>): {
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  currentItems: T[]
  pageCount: number
  needsPagination: boolean
} {
  const [currentPage, setCurrentPage] = useState(1)
  const indexOfLastRecord = currentPage * RECORDS_PER_PAGE
  const indexOfFirstRecord = indexOfLastRecord - RECORDS_PER_PAGE

  const currentItems = data.slice(indexOfFirstRecord, indexOfLastRecord)
  const pageCount = Math.ceil(data.length / RECORDS_PER_PAGE)
  const needsPagination = data.length > RECORDS_PER_PAGE

  return { currentPage, setCurrentPage, currentItems, pageCount, needsPagination }
}

export default usePagination
