import { useQuery } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CenteredLoadingIndicator from '../../../../../components/CenteredLoadingIndicator/CenteredLoadingIndicator.component'
import Heading from '../../../../../components/Heading'
import Paragraph from '../../../../../components/Paragraph/Paragraph.component'
import { setSpinner } from '../../../../../redux/slices/spinnerSlice'
import {
  WeakIdentificationRequestKeys,
  handleWeakIdentificationAuthorizationLink,
} from '../../WeakIdentification.requests'
import { FormInputElementDefaultProps } from '../../WeakIdentification.schema'
import { getService, setWeakIdentificationRedirectUrl } from '../../WeakIdentification.utils'

/**
 * WeakIdentification e-mail step form.
 * @param props
 * @returns {FunctionComponent<FormInputElementDefaultProps>}
 */
const EmailForm: FunctionComponent<FormInputElementDefaultProps> = (
  props: FormInputElementDefaultProps
) => {
  const { dispatch, t } = props
  // We need to use useTranslation hook for this occasion
  const { i18n } = useTranslation()
  // Reference for iframe to make it work more dynamically
  const iframeReference = useRef<HTMLIFrameElement>(null)
  const [displayIframe, setDisplayIframe] = useState<boolean>(false)
  const parsedService = getService()
  const authorizationRequest = useQuery(
    [WeakIdentificationRequestKeys.authenticationStep],
    () => handleWeakIdentificationAuthorizationLink(),
    { retry: false }
  )

  // UseEffect in here is used to update the iframe src attribute with the authorizationRequest data.
  // We hook it to use isLoading due we don't want this to be rendered multiple times even if the state
  // changes.
  useEffect(() => {
    dispatch(setSpinner({ visible: authorizationRequest.isLoading }))

    if (authorizationRequest.isLoading) {
      return
    }

    // We set the iframes src attribute to the authorizationRequest data and add the locale to the query
    if (authorizationRequest.data && iframeReference.current) {
      const src = `${authorizationRequest.data}&locale=${i18n.language}`
      iframeReference.current.src = src
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationRequest.isLoading, i18n.language, authorizationRequest.data])

  const handleIframeLoad = () => {
    setDisplayIframe(true)
    setWeakIdentificationRedirectUrl()
  }

  // Add loading indicator in here
  if (authorizationRequest.isLoading) {
    return null
  }

  return (
    <div style={{ marginTop: '1rem' }}>
      <Heading level={'h4'} color={'bf-blue'}>
        {
          t('weak-identification.email-information-form-title', {
            serviceName: parsedService?.name,
          }) as string
        }
      </Heading>
      <Paragraph>{t('weak-identification.email-information-form-description') as string}</Paragraph>
      {!displayIframe && <CenteredLoadingIndicator />}
      <iframe
        sandbox={
          'allow-forms allow-same-origin allow-top-navigation allow-scripts allow-popups allow-popups-to-escape-sandbox'
        }
        referrerPolicy="origin"
        width={'100%'}
        name={'Weak authentication email form'}
        onLoad={() => handleIframeLoad()}
        ref={iframeReference}
        style={{
          overflow: 'hidden',
          border: 'none',
          minHeight: displayIframe ? 500 : 0,
          visibility: displayIframe ? 'inherit' : 'hidden',
        }}
      />
    </div>
  )
}

export { EmailForm }
