import { useMutation } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import verifications from '../../api/verifications'
import Button from '../../components/Button'
import Container from '../../components/Container'
import Heading from '../../components/Heading'
import Input from '../../components/Input'
import Modal from '../../components/Modal'
import Text from '../../components/Text'
import {
  QUERY_KEY_PROCESS_EMAIL_VERIFICATION,
  QUERY_KEY_START_EMAIL_VERIFICATION,
} from '../../constants'
import { wrapWithStyledError } from '../../hooks/useValidators'
import { setSpinner } from '../../redux/slices/spinnerSlice'
import IEmailVerificationModal from './EmailVerificationModal.interface'

const EmailVerificationModal: FunctionComponent<IEmailVerificationModal> = ({
  isOpen,
  onAfterSubmit,
  onCancel,
  email,
}) => {
  const { t } = useTranslation()
  const [reference, setReference] = useState()
  const [token, setToken] = useState()
  const [error, setError] = useState<null | string>('')

  const dispatch = useDispatch()

  const startVerificationProcessMutation = useMutation({
    mutationKey: [QUERY_KEY_START_EMAIL_VERIFICATION],
    mutationFn: (ema: string) => verifications.startEmailVerification(ema),
  })

  const processVerificationResponseMutation = useMutation({
    mutationKey: [QUERY_KEY_PROCESS_EMAIL_VERIFICATION],
    mutationFn: (obj: { reference: string; token: string }) =>
      verifications.processVerificationResponse(obj),
  })

  useEffect(() => {
    setError('')

    if (
      startVerificationProcessMutation.status === 'success' &&
      startVerificationProcessMutation.data.reference
    ) {
      setReference(startVerificationProcessMutation.data.reference)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startVerificationProcessMutation.status, startVerificationProcessMutation.data])

  useEffect(() => {
    if (
      processVerificationResponseMutation?.data?.status === 'Error' ||
      processVerificationResponseMutation.data?.errors?.length >= 0
    ) {
      setError(t('email-validation.verification-code-invalid'))
      dispatch(setSpinner({ visible: false }))
    } else if (
      processVerificationResponseMutation.status === 'success' &&
      processVerificationResponseMutation.data.verificationKey &&
      onAfterSubmit
    ) {
      onAfterSubmit(processVerificationResponseMutation.data.verificationKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onAfterSubmit,
    processVerificationResponseMutation.data,
    processVerificationResponseMutation.status,
  ])

  const handleAfterOpen = () => {
    startVerificationProcessMutation.mutate(email)
  }

  const handleSubmit = () => {
    if (reference && token) {
      dispatch(setSpinner({ visible: true }))
      processVerificationResponseMutation.mutate({ reference, token })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    setError('')
    setToken(event.target.value)
  }

  const handleClose = () => {
    if (onCancel) {
      onCancel()
    }
  }

  const renderError = (): JSX.Element | undefined => {
    if (error) {
      return (
        <Container gap="none" padding="none none small none" margin="none">
          {wrapWithStyledError(error)}
        </Container>
      )
    }

    return undefined
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} onAfterOpen={handleAfterOpen}>
      <Heading level="h2" visualLevel="h4" color="bf-blue">
        {t('email-validation.title')}
      </Heading>
      <Text as="p">{t('email-validation.ingress')}</Text>
      {reference ? (
        <Container backgroundColor="bf-light-blue" textAlign="center" gap="small">
          <Input
            name="otp"
            label={t('email-validation.verification-code')}
            type="text"
            maxLength={6}
            onChange={handleChange}
            hasError={!!error}
          />
          {renderError()}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button label={t('microcopies.close')} onClick={handleClose} variant="secondary" />
            <Button label={t('microcopies.next')} onClick={handleSubmit} />
          </div>
        </Container>
      ) : undefined}
    </Modal>
  )
}

export default EmailVerificationModal
