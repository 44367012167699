/* eslint-disable sonarjs/no-duplicate-string */
import { UserRole } from '../shared/enums'
import { IOrganizationDetails, IRequest, IUserInformation } from '../shared/interfaces'

const getOrganizationByBusinessId = async (
  businessId: string
): Promise<IOrganizationDetails | undefined> => {
  const response = await fetch(`/api/organizations/${businessId}`)
  const data = await response.json()

  return data.value
}

const getOrganizationUsers = async (
  businessId: string
): Promise<Array<IUserInformation> | undefined> => {
  const response = await fetch(`/api/organizations/${businessId}/users`)
  const data = await response.json()

  return data.value
}

const getOrganizationRequests = async (
  businessId: string
): Promise<Array<IRequest> | undefined> => {
  const response = await fetch(`/api/organizations/${businessId}/requests`)
  const data = await response.json()

  if (data.error) throw new Error('Error fetching the requests')

  return data.value
}

const getCrmOrganizationByBusinessId = async (businessId: string) => {
  const response = await fetch(`/api/organizations/search?search=${businessId}`)
  const parsed = await response.json()
  const data = parsed.data

  if (data.error) throw new Error('Error fetching the requests')

  return data
}

const createRequest = async (obj: { businessId: string; role: string; message: string }) => {
  const role: number = obj.role === 'consultant' ? UserRole.consultant : UserRole.employee

  const response = await fetch(`/api/organizations/${obj.businessId}/requests`, {
    method: 'POST',
    body: JSON.stringify({
      role,
      message: obj.message,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return await response.json()
}

const createRequestInRegisteration = async (obj: {
  businessId: string
  role: string
  message: string
}) => {
  const role: number = obj.role === 'consultant' ? UserRole.consultant : UserRole.employee

  const response = await fetch(`/api/organizations/${obj.businessId}/requests?from=registeration`, {
    method: 'POST',
    body: JSON.stringify({
      role,
      message: obj.message,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return await response.json()
}

const acceptRequest = async (obj: { businessId: string; requestId: string }) => {
  const response = await fetch(
    `/api/organizations/${obj.businessId}/requests/${obj.requestId}/accept`,
    {
      method: 'PUT',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return await response.json()
}

const rejectRequest = async (obj: { businessId: string; requestId: string }) => {
  const response = await fetch(
    `/api/organizations/${obj.businessId}/requests/${obj.requestId}/reject`,
    {
      method: 'PUT',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return await response.json()
}

const removeUserFromOrganization = async (obj: { businessId: string; userId: string }) => {
  const response = await fetch(`/api/organizations/${obj.businessId}/users/${obj.userId}`, {
    method: 'DELETE',
  })

  return await response.json()
}

const skipOrganizationSelection = async () => {
  const response = await fetch('/api/organizations/skip', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return await response.json()
}

export default {
  getOrganizationByBusinessId,
  getOrganizationUsers,
  getOrganizationRequests,
  getCrmOrganizationByBusinessId,
  createRequest,
  acceptRequest,
  rejectRequest,
  removeUserFromOrganization,
  createRequestInRegisteration,
  skipOrganizationSelection,
}
