import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ColorEnum } from '../../shared/enums'
import IAlert from './Alert.interface'

const StyledAlert = styled.div`
  padding: 1rem 2rem;
  border: 1px solid;

  &.error {
    background-color: ${ColorEnum.bfPink};
    border-color: ${ColorEnum.bfDarkPink};
  }
`

const Alert: FunctionComponent<IAlert> = ({ role, variant, children }) => {
  return (
    <StyledAlert className={`bfsso-alert ${variant}`} role={role}>
      {children}
    </StyledAlert>
  )
}

export default Alert
