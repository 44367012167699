import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from '../../components/Button/Button'
import Heading from '../../components/Heading/Heading'
import { DEFAULT_SERVICE_ID, SESSION_KEY_SERVICE } from '../../constants'
import HeaderContainer from '../../containers/HeaderContainer/HeaderContainer'
import RouteWrapper from '../../containers/RouteWrapper/RouteWrapper'
import { ColorEnum, Spacing } from '../../shared/enums'
import { IServiceEntity } from '../../shared/interfaces'
import { ButtonContainer } from '../Portal/UserProfile/UserProfile.style'
import Container from './../../components/Container'

const Main = styled.main`
  width: 100%;
  background-color: ${ColorEnum.white};
  color: ${ColorEnum.black};
`

const ContentWrapper = styled.div`
  max-width: 44.625rem;
  margin: auto;
  padding: ${Spacing.ExtraLarge};
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const Logout: FunctionComponent = () => {
  const { t: translate } = useTranslation()

  let redirectUrl = '/'

  const storedService = sessionStorage.getItem(SESSION_KEY_SERVICE)

  if (storedService) {
    const service: IServiceEntity = JSON.parse(storedService)

    if (service.id !== DEFAULT_SERVICE_ID) {
      redirectUrl = `/?serviceId=${service.id}`
    }
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleNavigateToLogin = () => {
    window.location.assign(redirectUrl)
  }

  return (
    <RouteWrapper title={translate('logout.title')}>
      <HeaderContainer />
      <ContentWrapper>
        <Heading level="h1" visualLevel="h4" color="bf-blue">
          {translate('logout.title')}
        </Heading>
        <Main>
          <Container gap="extra-large" padding="none" color="bf-blue">
            <p>{translate('logout.tip')}</p>
            <ButtonContainer>
              <Button
                label={translate('logout.to-login-button-label')}
                onClick={handleNavigateToLogin}
              />
            </ButtonContainer>
          </Container>
        </Main>
      </ContentWrapper>
    </RouteWrapper>
  )
}

export default Logout
