import styled from 'styled-components'

import { Breakpoint, ColorEnum, Spacing } from '../../shared/enums'

export const Wrapper = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  max-width: ${Breakpoint.ExtraLarge};
  margin: 0 auto;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${Spacing.ExtraLarge};
  color: ${ColorEnum.bfBlue};

  @media (max-width: ${Breakpoint.Large}) {
    width: 100%;
    height: 100%;
    padding: ${Spacing.Large};
    flex-direction: column;
  }
`

export const Page = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
`

export const Content = styled.main`
  flex: 1;
`
