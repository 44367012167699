/* eslint-disable react/jsx-no-bind */
import { Children, FocusEvent, FunctionComponent, cloneElement, isValidElement } from 'react'
import { Form as FormComponent } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import Alert from '../../components/Alert'
import IButton from '../../components/Button/Button.interface'
import Text from '../../components/Text'
import FieldContainer from './FieldContainer'
import IFieldContainer from './FieldContainer/FieldContainer.interface'
import FieldSetContainer from './FieldSetContainer'
import IFieldSetContainer from './FieldSetContainer/FieldSetContainer.interface'
import IFormContainer from './FormContainer.interface'

const FormContainer: FunctionComponent<IFormContainer> & {
  Field: FunctionComponent<IFieldContainer>
  FieldSet: FunctionComponent<IFieldSetContainer>
} = ({
  onSubmit,
  validator,
  externalValidationErrors,
  children,
  touchEnable = false,
  formRef,
  errorRef,
  onBlur,
}) => {
  const { t: translate } = useTranslation()

  const handleOnBlur = (event: FocusEvent<HTMLFormElement, Element>) => {
    if (onBlur) {
      const key = event.target.name
      const value = event.target['value']

      return onBlur(key, value)
    }
  }

  return (
    <FormComponent
      className="bfsso-form"
      onSubmit={onSubmit}
      validate={validator}
      render={({ handleSubmit, submitting, form }) => {
        if (formRef) {
          formRef.current = form
        }

        return (
          <form onBlur={event => handleOnBlur(event)} onSubmit={handleSubmit}>
            {externalValidationErrors && externalValidationErrors.length > 0 ? (
              <div>
                <Alert variant="error">
                  <Text size="large" weight="bold" ref={errorRef}>
                    {translate('microcopies.form-has-errors')}
                  </Text>
                  <ul>
                    {externalValidationErrors.map(error => (
                      <li key="error">{error.message}</li>
                    ))}
                  </ul>
                </Alert>
              </div>
            ) : null}
            {Children.map(children, child => {
              if (isValidElement<IButton>(child) && child.props.type === 'submit') {
                const hasValidationErrors = form.getState().hasValidationErrors
                const hasBeenEdited = form.getState().dirty
                const disabled =
                  (touchEnable && !hasBeenEdited) || hasValidationErrors || submitting

                return cloneElement(child, { disabled })
              }

              return child
            })}
          </form>
        )
      }}
    />
  )
}

FormContainer.Field = FieldContainer
FormContainer.FieldSet = FieldSetContainer

export default FormContainer
