import { useQuery } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import CenteredLoadingIndicator from '../../../components/CenteredLoadingIndicator/CenteredLoadingIndicator.component'
import {
  WeakIdentificationRequestKeys,
  handleOnBoardingStatus,
  handleSessionCheckForWeakIdentification,
  handleSilentLogout,
} from './WeakIdentification.requests'
import {
  FormConfiguration,
  FormInput,
  WeakIdentificationSearchQueryType,
  searchQueryParameters,
} from './WeakIdentification.schema'
import { WeakIdentificationUrls } from './WeakIdentification.urls'
import { getServiceId } from './WeakIdentification.utils'
import { EmailForm } from './components/forms/EmailForm.component'
import { JoinToOrganizationForm } from './components/forms/JoinToOrganizationForm.component'
import { MiscInformationForm } from './components/forms/MiscInformationForm.component'
import { WeakIdentificationLayoutWrapper } from './components/layout/WeakIdentificationLayoutWrapper.component'
import { useCheckWeakIdentificationRouteValidity } from './hooks/WeakIdentification.hooks'

const ONE_MINUTE = 60_000

/**
 * ViewIndexes for the stepper
 */
enum ViewIndexes {
  email = 1,
  miscInformation = 2,
  joinToOrganization = 3,
}

/**
 * Configuration for form inputs to be rendered
 */
const WeakIdentificationSubRoutes: FormConfiguration = {
  [FormInput.email]: {
    element: EmailForm,
    indexNumber: ViewIndexes.email,
  },
  [FormInput.miscInformation]: {
    element: MiscInformationForm,
    indexNumber: ViewIndexes.miscInformation,
  },
  [FormInput.joinToOrganization]: {
    element: JoinToOrganizationForm,
    indexNumber: ViewIndexes.joinToOrganization,
  },
}

/**
 * Basic wrapper for WeakIdentification workflow
 * @returns {FunctionComponent}
 */
const WeakIdentification: FunctionComponent = () => {
  const navigation = useNavigate()
  // We call this only once to prevent a duplication of code and pass this as a prop to children
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const service = getServiceId()
  const location = useLocation()
  const previousLocation = useRef(location)
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
  const searchParamsValidation = useCheckWeakIdentificationRouteValidity()
  const currentView = searchParamsValidation.searchParams.get(
    searchQueryParameters.view
  ) as FormInput

  const sessionCheckRequest = useQuery({
    queryKey: [WeakIdentificationRequestKeys.session],
    queryFn: handleSessionCheckForWeakIdentification,
    refetchInterval: ONE_MINUTE,
    enabled: currentView !== FormInput.email,
  })

  const onBoardingStatusRequest = useQuery({
    queryKey: [WeakIdentificationRequestKeys.onBoardingStatus],
    queryFn: handleOnBoardingStatus,
  })

  const silentLogoutRequest = useQuery({
    queryKey: [WeakIdentificationRequestKeys.silentLogout],
    queryFn: handleSilentLogout,
    enabled: false,
  })

  /**
   * Gets the search parameters from the URL
   * @returns {Object} search parameters
   */
  const getSearchParameters = () => {
    const searchParameters = new URLSearchParams(window.location.search)
    const view = searchParameters.get(searchQueryParameters.view) as FormInput

    return {
      view,
    } as WeakIdentificationSearchQueryType
  }

  /**
   * Handles the changes of search parameters adding the new value to the search query
   * @param key
   * @param value
   */
  const setSearchParameter = (key: searchQueryParameters, value: FormInput) => {
    const searchParameters = getSearchParameters()

    if (key === searchQueryParameters.view) {
      searchParameters.view = value as FormInput
    }

    navigation({
      pathname: WeakIdentificationUrls.basePath,
      search: `?view=${searchParameters.view}`,
    })
  }

  /**
   * Basic helper function that handles router navigation of the view
   * @param key
   */
  const setView = (key: FormInput) => {
    setSearchParameter(searchQueryParameters.view, key)
  }

  /**
   * To prevent user to go to the next step without the session check we need to check if the session
   * exists. If not we navigate the user to the email step.
   */
  useEffect(() => {
    if (sessionCheckRequest.isLoading) {
      return
    }

    if (!sessionCheckRequest.data && currentView !== FormInput.email) {
      navigation({
        pathname: '/register/weak-identification',
        search: `?view=${FormInput.email}`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionCheckRequest.data])

  /**
   * This useEffect handles browsers previous page attempts.
   * For example miscInformation always returns error if user tries to go back and
   * send same data again. This is due we don't have an opportunity to update the
   * data in the UBI side with the request.
   */
  useEffect(() => {
    if (
      location.key !== previousLocation.current.key &&
      location.pathname === previousLocation.current.pathname
    ) {
      onBoardingStatusRequest.remove()
      onBoardingStatusRequest.refetch()
    }

    previousLocation.current = location
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  /**
   * This useEffect handles the situation where the user might stop
   * on-boarding. For example user has saved a misc information but doesn't
   * have joined the organization yet.
   */
  useEffect(() => {
    if (onBoardingStatusRequest.isLoading) {
      return
    }

    if (!onBoardingStatusRequest.data) {
      return
    }

    // This is for preventing the "blink" of an component while user is redirected
    setIsRedirecting(true)

    const { hasFilledAdditionalInformation, redirectUrl } = onBoardingStatusRequest.data

    // This is fresh start without any sessions
    if (!hasFilledAdditionalInformation) {
      setIsRedirecting(false)

      return
    }

    // If user can skip the organization select we can redirect user to the service
    if (redirectUrl) {
      silentLogoutRequest.refetch()

      return window.location.assign(redirectUrl)
    }

    let view: FormInput = FormInput.miscInformation

    // If user has ubisecure information we can skip the misc information step and add user to join
    // to organization. If user tries to join to the same organization we let user do the basic
    // workflow without actually joining the organization multiple times.
    if (hasFilledAdditionalInformation) {
      view = FormInput.joinToOrganization
    }

    setIsRedirecting(false)

    // Last check to prevent duplicate renders
    if (currentView !== view) {
      setView(view)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBoardingStatusRequest.data])

  /**
   * When searchParams change we want to validate if the route is valid.
   * If not we navigate to the root.
   */
  useEffect(() => {
    const isValid = searchParamsValidation.validate()

    if (!isValid) {
      navigation({
        pathname: '/',
      })
    }
  }, [searchParamsValidation.searchParams, searchParamsValidation, navigation])

  if (!currentView || !WeakIdentificationSubRoutes[currentView] || !service) {
    return <div />
  }

  /**
   * This function handles form onSuccesses. Basically we check the next key of
   * on configuration object and push router to the next possible place.
   * @param currentFormInput
   * @returns {void}
   */
  const handleOnSuccess = (currentFormInput: FormInput) => {
    const currentWeakIdentificationSubRouteKeys: FormInput[] = Object.keys(
      WeakIdentificationSubRoutes
    ) as FormInput[]

    if (!currentWeakIdentificationSubRouteKeys.includes(currentFormInput)) {
      // This happens when currentFormInput doesn't exist. We stop the function call in there
      // if this would ever happen. Due we have an validation for searchParameters we don't
      // have this issue like ever but sometimes things fails so it's important to have this kind
      // of checks.
      return
    }

    const currentKeyIndex = currentWeakIdentificationSubRouteKeys.indexOf(currentFormInput)

    // If currentKeyIndex doesn't exist the value is -1
    if (currentKeyIndex < 0) {
      // This shouldn't happen neither but to be sure lets add this statement here also
      return
    }

    // This is quite ugly way to handle this. Due currentKeyIndex is an constant we can't use currentKeyIndex++ kind
    // of approach without mutating also the original value.
    const nextKeyIndex = currentKeyIndex + 1
    // We check if the nextKey exists
    const nextKey = currentWeakIdentificationSubRouteKeys[nextKeyIndex]

    if (!nextKey) {
      // this shouldn't happen like ever
      return
    }

    // Change searchqueries "view" parameter to step on the next step
    return setView(nextKey)
  }

  // Shorthand to find a current routes location
  const component = WeakIdentificationSubRoutes[currentView]

  return (
    <WeakIdentificationLayoutWrapper
      hideSteps={isRedirecting}
      numberOfSteps={Object.keys(WeakIdentificationSubRoutes).length}
      currentStep={onBoardingStatusRequest.isLoading ? -1 : component.indexNumber}
    >
      {onBoardingStatusRequest.isLoading || isRedirecting ? <CenteredLoadingIndicator /> : null}
      {!onBoardingStatusRequest.isLoading && !isRedirecting && (
        <component.element
          service={service}
          t={t}
          dispatch={dispatch}
          onSuccess={(currentFormKey: FormInput) => handleOnSuccess(currentFormKey)}
        />
      )}
    </WeakIdentificationLayoutWrapper>
  )
}

export default WeakIdentification
