const getReceivedInvitations = async () => {
  const response = await fetch('/api/me/received-invitations')

  const data = await response.json()

  return data.value
}

const getSentInvitations = async () => {
  const response = await fetch('/api/me/invitations')

  const data = await response.json()

  return data.value
}

const getReceivedInvitationById = async (invitationId: string) => {
  const response = await fetch(`/api/me/received-invitations/${invitationId}`)

  const data = await response.json()

  return data.value
}

const cancelInvitation = async (invitationId: string) => {
  const response = await fetch(`/api/me/invitations/${invitationId}`, {
    method: 'DELETE',
  })

  const data = await response.json()

  return data.value
}

const acceptInvitation = async (invitationId: string) => {
  const response = await fetch(`/api/me/received-invitations/${invitationId}/accept`, {
    method: 'PUT',
    body: null,
  })

  const data = await response.json()

  return data.value
}

const rejectInvitation = async (invitationId: string) => {
  const response = await fetch(`/api/me/received-invitations/${invitationId}/reject`, {
    method: 'PUT',
    body: null,
  })

  const data = await response.json()

  return data.value
}

const sendInvitation = async (businessId: string, email: string, role: number) => {
  const response = await fetch(`/api/organizations/${businessId}/invitations`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, role }),
  })

  const data = await response.json()

  return data.value
}

export default {
  getReceivedInvitations,
  getSentInvitations,
  getReceivedInvitationById,
  cancelInvitation,
  acceptInvitation,
  rejectInvitation,
  sendInvitation,
}
