/* eslint-disable unicorn/consistent-function-scoping */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FunctionComponent, ReactNode, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'

import invitations from '../../../../api/invitations'
import organizations from '../../../../api/organizations'
import Button from '../../../../components/Button'
import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import Pagination from '../../../../components/Pagination'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import Text from '../../../../components/Text'
import {
  QUERY_KEY_MY_RECEIVED_INVITATIONS,
  QUERY_KEY_RECEIVED_REQUESTS,
} from '../../../../constants'
import DataGridContainer from '../../../../containers/DataGridContainer'
import useL3Access from '../../../../hooks/useL3Access'
import usePagination from '../../../../hooks/usePagination'
import { RootState } from '../../../../redux/store'
import { NotificationType } from '../../../../shared/enums'
import { INotification } from '../../../../shared/types'
import { isInvitation } from '../../../../utils/notification-utils'
import { NotificationRow } from '../Notifications.interface'
import IReceived from './Received.interface'

const mapStateToProps = (state: RootState) => {
  return {
    notifications: state.me.notifications,
  }
}

const ButtonContainer = styled.div`
  display: flex;
`

const Received: FunctionComponent<IReceived> = ({ notifications }) => {
  const { t: translate } = useTranslation()
  const hasL3Access = useL3Access()
  const dispatch = useDispatch()

  const queryClient = useQueryClient()
  const acceptRequestMutation = useMutation({
    mutationKey: [''],
    mutationFn: (obj: any) => organizations.acceptRequest(obj),
  })
  const rejectRequestMutation = useMutation({
    mutationKey: [''],
    mutationFn: (obj: any) => organizations.rejectRequest(obj),
  })
  const acceptInvitationMutation = useMutation({
    mutationFn: (id: string) => invitations.acceptInvitation(id),
  })
  const rejectInvitationMutation = useMutation({
    mutationFn: (id: string) => invitations.rejectInvitation(id),
  })

  useEffect(() => {
    if (acceptRequestMutation.status === 'success') {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_RECEIVED_REQUESTS],
      })
      dispatch(openSnackbar(translate('snackbar.request-accept-success'), false))
    } else if (acceptRequestMutation.status === 'error') {
      dispatch(openSnackbar(translate('snackbar.request-accept-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptRequestMutation.status, queryClient])

  useEffect(() => {
    if (rejectRequestMutation.status === 'success') {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_RECEIVED_REQUESTS],
      })
      dispatch(openSnackbar(translate('snackbar.request-reject-success'), false))
    } else if (rejectInvitationMutation.status === 'error') {
      dispatch(openSnackbar(translate('snackbar.request-reject-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectRequestMutation.status, queryClient])

  useEffect(() => {
    if (acceptInvitationMutation.status === 'success') {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_MY_RECEIVED_INVITATIONS],
      })
      dispatch(openSnackbar(translate('snackbar.accept-invitation-success'), false))
    } else if (acceptInvitationMutation.status === 'error') {
      dispatch(openSnackbar(translate('snackbar.accept-invitation-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptInvitationMutation.status, queryClient])

  useEffect(() => {
    if (rejectInvitationMutation.status === 'success') {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_MY_RECEIVED_INVITATIONS],
      })
      dispatch(openSnackbar(translate('snackbar.reject-invitation-success'), false))
    } else if (acceptInvitationMutation.status === 'error') {
      dispatch(openSnackbar(translate('snackbar.reject-invitation-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectInvitationMutation.status, queryClient])

  const notificationColumns = [
    { key: 'notifications', name: translate('microcopies.notifications') },
    { key: 'actions', name: translate('microcopies.actions') },
  ]

  const notificationRows = useMemo(() => {
    if (notifications) {
      return notifications.reduce((acc: Array<NotificationRow>, current: INotification) => {
        const tKey = isInvitation(current) ? 'invitations.received-invitation' : 'requests.request'

        const fullName = isInvitation(current)
          ? `${current.sender.firstname} ${current.sender.surname}`
          : `${current.user.firstname} ${current.user.surname}`

        const rowData = {
          id: current.id,
          type: isInvitation(current) ? NotificationType.Invitation : NotificationType.Request,
          businessId: current.organization.businessId,
          text: (
            <Trans
              i18nKey={tKey}
              t={translate}
              components={{
                link1: <Text weight="bold">{fullName}</Text>,
                link2: <Text weight="bold">{current.organization.name}</Text>,
              }}
              tOptions={{
                fullName: fullName,
                organization: current.organization.name,
              }}
            />
          ),
        }

        acc.push(rowData)

        return acc
      }, [])
    }

    return []
  }, [notifications, translate])

  const previousLabel = translate('microcopies.previous')
  const nextLabel = translate('microcopies.next')

  const { needsPagination, currentItems, pageCount, currentPage, setCurrentPage } = usePagination(
    notificationRows || []
  )

  const title = translate('requests.received-requests-title')
  const titleWithRequestN = !notifications ? title : `${title} (${notifications.length})`

  return (
    <Container gap="small">
      <Heading level="h2" visualLevel="h5" color="bf-blue">
        {titleWithRequestN}
      </Heading>

      {!notifications && <Text>{translate('requests.requests-not-found')}</Text>}

      <DataGridContainer>
        <DataGridContainer.Header>
          {notificationColumns
            ? notificationColumns.map(col => {
                return <DataGridContainer.Column key={col.name} name={col.name} />
              })
            : undefined}
        </DataGridContainer.Header>
        <DataGridContainer.Body>
          {currentItems
            ? currentItems.map((row: NotificationRow, rowIndex: number) => {
                const { id, type, businessId, ...rest } = row

                const handleAccept =
                  type === NotificationType.Invitation
                    ? (invitationId: string) => acceptInvitationMutation.mutate(invitationId)
                    : (requestId: string) => acceptRequestMutation.mutate({ businessId, requestId })
                const handleReject =
                  type === NotificationType.Invitation
                    ? (invitationId: string) => rejectInvitationMutation.mutate(invitationId)
                    : (requestId: string) => rejectRequestMutation.mutate({ businessId, requestId })

                return (
                  <DataGridContainer.Row key={rowIndex}>
                    <>
                      {Object.values(rest).map((cell: ReactNode, cellIndex: number) => {
                        return (
                          <DataGridContainer.Cell key={cellIndex}>{cell}</DataGridContainer.Cell>
                        )
                      })}
                    </>
                    <DataGridContainer.Cell>
                      <ButtonContainer>
                        <Button
                          label={translate('microcopies.accept')}
                          icon="check"
                          iconRight
                          onClick={() => handleAccept(id)}
                          disabled={!hasL3Access}
                        />
                        <Button
                          label={translate('microcopies.decline')}
                          iconOnly
                          icon="trash"
                          variant="negative-plain"
                          onClick={() => handleReject(id)}
                          disabled={!hasL3Access}
                        />
                      </ButtonContainer>
                    </DataGridContainer.Cell>
                  </DataGridContainer.Row>
                )
              })
            : undefined}
        </DataGridContainer.Body>
      </DataGridContainer>

      {!!needsPagination && (
        <Container padding="none" justifyContent="center" flexDirection="row">
          <Pagination
            previousLabel={previousLabel}
            nextLabel={nextLabel}
            pageCount={pageCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      )}
    </Container>
  )
}

// eslint-disable-next-line react-redux/prefer-separate-component-file
export default connect(mapStateToProps)(Received)
