import styled from 'styled-components'

import { FontSize, FontWeight } from '../../shared/enums'
import { colorPropToHex, fontSizePropToRem, fontWeightPropToValue } from '../../utils/style-utils'
import IText from './Text.interface'

const Text = styled.span<IText>(props => ({
  color: props.color ? colorPropToHex(props.color) : 'inherit',
  // eslint-disable-next-line unicorn/explicit-length-check
  fontSize: props.size ? fontSizePropToRem(props.size) : FontSize.Normal,
  fontWeight: props.weight ? fontWeightPropToValue(props.weight) : FontWeight.Normal,
  whiteSpace: 'pre-line',
}))

export default Text
