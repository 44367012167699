import classNames from 'classnames'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg'
import { ReactComponent as CheckCircleIcon } from '../../assets/icons/check-circle.svg'
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as Dangerous } from '../../assets/icons/dangerous.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ReactComponent as EllipsisIcon } from '../../assets/icons/ellipsis.svg'
import { ReactComponent as ExternalBoldIcon } from '../../assets/icons/external-bold.svg'
import { ReactComponent as ExternalIcon } from '../../assets/icons/external.svg'
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg'
import { ReactComponent as GlobeIcon } from '../../assets/icons/globe.svg'
import { ReactComponent as HideIcon } from '../../assets/icons/hide.svg'
import { ReactComponent as LanguageIcon } from '../../assets/icons/language.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg'
import { ReactComponent as MailIcon } from '../../assets/icons/mail.svg'
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg'
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg'
import { IconHeight, IconWidth } from '../../shared/enums'
import { colorPropToHex } from '../../utils/style-utils'
import IICon from './Icon.interface'

const iconVariants = {
  'arrow-down': ArrowDownIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  check: CheckIcon,
  'check-circle': CheckCircleIcon,
  close: CloseIcon,
  dangerous: Dangerous,
  edit: EditIcon,
  ellipsis: EllipsisIcon,
  external: ExternalIcon,
  eye: EyeIcon,
  globe: GlobeIcon,
  hide: HideIcon,
  language: LanguageIcon,
  minus: MinusIcon,
  mail: MailIcon,
  phone: PhoneIcon,
  plus: PlusIcon,
  trash: TrashIcon,
  'external-bold': ExternalBoldIcon,
  warning: WarningIcon,
  logout: LogoutIcon,
}

const StyledIcon = styled.svg`
  width: ${IconWidth.Medium};
  height: ${IconHeight.Medium};

  &.bfsso-icon.smallest {
    width: ${IconWidth.Smallest};
    height: ${IconHeight.Smallest};
  }

  &.bfsso-icon.small {
    width: ${IconWidth.Small};
    height: ${IconHeight.Small};
  }

  &.bfsso-icon.large {
    width: ${IconWidth.Large};
    height: ${IconHeight.Large};
  }
`

const Icon: FunctionComponent<IICon> = ({ name, color = 'black', size = 'medium', className }) => {
  const computedClass = classNames('bfsso-icon', size, className)
  const Component = iconVariants[name]
  const fillColor = colorPropToHex(color)

  return <StyledIcon as={Component} className={computedClass} fill={fillColor} />
}

export default Icon
