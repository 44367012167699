import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IUserInformation } from '../../shared/interfaces'
import { AuthLevel, INotification } from '../../shared/types'
import { RootState } from '../store'

type MeState = {
  uid: string | undefined
  authLevel: AuthLevel | undefined
  userInfo: IUserInformation | undefined
  notifications: Array<INotification>
}

const initialState = {
  uid: undefined,
  authLevel: undefined,
  userInfo: undefined,
  notifications: [],
} as MeState

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setUid: (state: MeState, action: PayloadAction<string>) => {
      state.uid = action.payload
    },
    setNotifications: (state: MeState, action: PayloadAction<Array<INotification>>) => {
      state.notifications = action.payload
    },
    setUserInfo: (state: MeState, action: PayloadAction<IUserInformation>) => {
      state.userInfo = action.payload
    },
    setAuthLevel: (state: MeState, action: PayloadAction<AuthLevel>) => {
      state.authLevel = action.payload
    },
  },
})

export const selectUid = (state: RootState) => state.me.uid
export const selectAuthLevel = (state: RootState) => state.me.authLevel
export const selectUserInfo = (state: RootState) => state.me.userInfo

export const { setUid, setNotifications, setUserInfo, setAuthLevel } = meSlice.actions

export default meSlice.reducer
