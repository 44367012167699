/* eslint-disable react/jsx-no-useless-fragment */
import { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import IRouteWrapper from './RouteWrapper.interface'

/**
 * **Wrapper for route views**
 *
 * Here you can:
 * - Read global url search params
 * - Add view specific metadata in head
 *
 * @prop `children` - children elements
 * @returns `children`
 */
const RouteWrapper: FunctionComponent<IRouteWrapper> = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <title>{title} - BF-ID</title>
      </Helmet>
      {children}
    </>
  )
}

export default RouteWrapper
