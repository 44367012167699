import { URLSearchParams } from 'node:url'

export const isExternalUrl = (url: string) => {
  try {
    const objectURL = new URL(url)

    return !(objectURL.host === window.location.host)
  } catch {
    return false
  }
}

/**
 * Converts searchParams into a record
 * @param searchParams
 * @returns {Record<string, string>}
 */
export const getSearchParamsAsObject = (searchParams: URLSearchParams): Record<string, string> => {
  const searchParamsAsObject: Record<string, string> = {}

  for (const [key, value] of searchParams) {
    searchParamsAsObject[key] = value
  }

  return searchParamsAsObject
}
