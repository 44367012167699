import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import invitations from '../../../../api/invitations'
import Button from '../../../../components/Button'
import Heading from '../../../../components/Heading'
import Modal from '../../../../components/Modal'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import { QUERY_KEY_MY_INVITATIONS } from '../../../../constants'
import FormContainer from '../../../../containers/FormContainer'
import { Spacing, UserRole } from '../../../../shared/enums'
import { FormErrors, FormValues } from '../../../../shared/types'

interface IInvitationModal {
  businessId: string
  isOpen?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose: any
}

const InvitationModal: FunctionComponent<IInvitationModal> = ({
  businessId,
  isOpen = false,
  onClose,
}) => {
  const { t: translate } = useTranslation()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const [externalValidationErrors, setExternalValidationErrors] = useState()

  const inviteUserMutation = useMutation({
    mutationFn: ({
      organizationId,
      userEmail,
      userRole,
    }: {
      organizationId: string
      userEmail: string
      userRole: UserRole
    }) => invitations.sendInvitation(organizationId, userEmail, userRole),
  })

  useEffect(() => {
    if (inviteUserMutation.status === 'success') {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_MY_INVITATIONS],
      })
      dispatch(openSnackbar(translate('snackbar.organization-invite-success'), false))
    }

    if (inviteUserMutation.data?.errors) {
      return setExternalValidationErrors(inviteUserMutation.data?.errors)
    }

    if (inviteUserMutation.status === 'error') {
      dispatch(openSnackbar(translate('snackbar.organization-invite-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, inviteUserMutation.status, inviteUserMutation.data?.errors])

  const handleSendInvitation = async (values: FormValues) => {
    //if (values['email'] && values['role']) {
    if (values['email']) {
      inviteUserMutation.mutate({
        organizationId: businessId,
        userEmail: values['email'] as string,
        // eslint-disable-next-line etc/no-commented-out-code
        //userRole: values['role'] as unknown as number,
        userRole: UserRole.employee,
      })
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <Heading level="h1" visualLevel="h4">
        {translate('organization-details.invite-more-users')}
      </Heading>
      <FormContainer
        onSubmit={handleSendInvitation}
        validator={(_values: FormValues) => {
          const errors: FormErrors = {}

          // eslint-disable-next-line etc/no-commented-out-code
          /*
          if (!values['role']) {
            errors['role'] = translate('invitations.must-select-role')
          }
          */

          return errors
        }}
        externalValidationErrors={externalValidationErrors}
      >
        <FormContainer.Field
          name="email"
          type="email"
          label={translate('microcopies.email')}
          placeholder={translate('microcopies.example-email') as string}
        />
        {/*
        <Container margin="large none extra-large none" padding="none">
          <fieldset style={{ display: 'flex', gap: '1rem' }}>
            <legend>{translate('ROOLI')}</legend>
            <Field name="role" type="radio" value="140110000">
              {({ input }) => {
                return (
                  <Radio
                    label="Employee"
                    name={input.name}
                    type="radio"
                    value="140110000"
                    checked={!!input.checked}
                    // eslint-disable-next-line react/jsx-handler-names
                    onChange={input.onChange}
                  />
                )
              }}
            </Field>
            <Field name="role" type="radio" value="140110001">
              {({ input }) => {
                return (
                  <Radio
                    label="Consultant"
                    name={input.name}
                    type="radio"
                    value="140110001"
                    checked={!!input.checked}
                    // eslint-disable-next-line react/jsx-handler-names
                    onChange={input.onChange}
                  />
                )
              }}
            </Field>
          </fieldset>
        </Container>
            */}
        <div style={{ float: 'left', marginRight: Spacing.Large }}>
          <Button
            label={translate('microcopies.cancel')}
            onClick={onClose}
            variant="negative"
            type="button"
          />
        </div>
        <Button label={translate('microcopies.send')} icon="mail" iconRight type="submit" />
      </FormContainer>
    </Modal>
  )
}

export default InvitationModal
