import {
  Children,
  FunctionComponent,
  cloneElement,
  createElement,
  isValidElement,
  useState,
} from 'react'
import styled from 'styled-components'

import StepButton from './StepButton'
import IStepper, { IStep } from './Stepper.interface'

const Step: FunctionComponent<IStep> = ({ component, ...rest }) => {
  const Component = component

  return <Component {...rest} />
}

const StepperButtons = styled.div`
  margin-bottom: 2rem;
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: center;

  @media (min-width: 768px) {
    gap: 4rem;
  }
`

const Stepper: FunctionComponent<IStepper> & { Step: FunctionComponent<IStep> } = ({
  children,
}) => {
  const stepsCount = Children.count(children)
  const [activeStep, setActiveStep] = useState<number>(0)

  const handleNext = () => {
    setActiveStep(prevActiveStep => {
      const nextStep = prevActiveStep + 1

      return nextStep < stepsCount ? nextStep : prevActiveStep
    })
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => {
      return prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep
    })
  }

  return (
    <div className="bfsso-stepper">
      <StepperButtons>
        {!!children &&
          Children.map(children, (step, index) => {
            return isValidElement<IStep>(step)
              ? createElement(StepButton, {
                  index: index + 1,
                  label: step.props.label,
                  active: activeStep === index,
                  disabled: activeStep < index,
                })
              : undefined
          })}
      </StepperButtons>

      <div className="bfsso-stepper-steps" aria-live="polite">
        {/** TODO: Optimize this (render active child without mapping them all if possible) */}
        {!!children &&
          Children.map(children, (step, index) => {
            const stepProps = { ...step.props, handleNext, handleBack }

            return activeStep === index ? cloneElement(step, { ...stepProps }) : undefined
          })}
      </div>
    </div>
  )
}

Stepper.Step = Step

export default Stepper
