import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import Heading from '../../../components/Heading'
import Spacer from '../../../components/Spacer'
import PortalRouteWrapper from '../../../containers/PortalRouteWrapper'
import useL3Access from '../../../hooks/useL3Access'
import JoinOrganization from './JoinOrganization'
import UserOrganizations from './UserOrganizations'

const Organizations: FunctionComponent = () => {
  const { t: translate } = useTranslation()
  const hasL3Access = useL3Access()

  return (
    <PortalRouteWrapper title={translate('organization.title')}>
      <Heading level="h1" visualLevel="h4" color="bf-blue">
        {translate('organization.title')}
      </Heading>

      {!hasL3Access ? <div>{translate('organization.modify-l3access')}</div> : null}

      <UserOrganizations />

      {hasL3Access ? (
        <>
          <Spacer size="small" />
          <JoinOrganization />
        </>
      ) : null}
    </PortalRouteWrapper>
  )
}

export default Organizations
