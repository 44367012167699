import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { Spacing } from '../../shared/enums'
import ISpacer from './Spacer.interface'

const StyledSpacer = styled.div`
  width: 100%;

  &.smallest {
    height: ${Spacing.Smallest};
  }
  &.extra-small {
    height: ${Spacing.ExtraSmall};
  }
  &.small {
    height: ${Spacing.Small};
  }
  &.medium {
    height: ${Spacing.Medium};
  }
  &.large {
    height: ${Spacing.Large};
  }
  &.extra-large {
    height: ${Spacing.ExtraLarge};
  }
  &.largest {
    height: ${Spacing.Largest};
  }
`

/**
 * Maybe it is too heavy to use div as a spacer?
 * Convert this to CSS classes (e.g. mx-small = small margin in x-axis)
 *
 * Use this only when no other viable solution exists
 */
const Spacer: FunctionComponent<ISpacer> = ({ size }) => {
  return <StyledSpacer className={`bfsso-spacer ${size}`} />
}

export default Spacer
