import styled from 'styled-components'

import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator.component'

const StyledCenteredLoadingIndicatorWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-content: center;
`

const CenteredLoadingIndicator: React.FC = () => {
  return (
    <StyledCenteredLoadingIndicatorWrapper>
      <LoadingIndicator />
    </StyledCenteredLoadingIndicatorWrapper>
  )
}

export default CenteredLoadingIndicator
