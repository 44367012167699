import { IServiceEntity } from './shared/interfaces'

// TODO: Load values from envs?
export const SITE_NAME = 'BF SSO'

export const ENVIRONMENT = process.env['REACT_APP_DEPLOYMENT_ENVIRONMENT'] || 'development'

/** n of records per page on data grids */
export const RECORDS_PER_PAGE = 5
export const NOTIFICATION_REFETCH_INTERVAL = 30_000

export const ERROR_404_NOT_FOUND = 'Requested resource(s) not found'
export const ERROR_500_UNEXPECTED = 'An unexpected error has occurred'

export const QUERY_KEY_AUTH_START_L1 = 'auth-start-tupas'
export const QUERY_KEY_AUTH_START_L2 = 'auth-start-l2'
export const QUERY_KEY_AUTH_START_L3 = 'auth-start-l3'
export const QUERY_KEY_AUTH_CALLBACK = 'auth-callback'
export const QUERY_KEY_AUTH_CHECK = 'auth-check'
export const QUERY_KEY_USER_PROFILE = 'user-info'
export const QUERY_KEY_USER_ENABLED = 'user-enabled'
export const QUERY_KEY_TOKEN_USER_INFO = 'token-user-info'
export const QUERY_KEY_MY_REQUESTS = 'my-requests'
export const QUERY_KEY_RECEIVED_REQUESTS = 'received-requests'
export const QUERY_KEY_USER_ORGANIZATIONS = 'user-organizations'
export const QUERY_KEY_ORGANIZATION_DETAILS = 'organization-details'
export const QUERY_KEY_ORGANIZATION_USERS = 'organization-users'
export const QUERY_KEY_START_EMAIL_VERIFICATION = 'start-email-verification'
export const QUERY_KEY_PROCESS_EMAIL_VERIFICATION = 'process-email-verification'
export const QUERY_KEY_SEND_REQUEST = 'send-request'
export const QUERY_KEY_CANCEL_REQUEST = 'cancel-request'
export const QUERY_KEY_LEAVE_ORGANIZATION = 'leave-organization'
export const QUERY_KEY_SERVICE_INFORMATION = 'service-information'
export const QUERY_KEY_GET_INVITATION = 'get-invitation'
export const QUERY_KEY_MY_INVITATIONS = 'my-invitations'
export const QUERY_KEY_MY_RECEIVED_INVITATIONS = 'my-received-invitations'
export const QUERY_KEY_SKIP_ORGANIZATION_SELECTION = 'skip-organization-selection'

export const SESSION_KEY_REDIRECT = 'redirect'
export const SESSION_KEY_SERVICE = 'service'

export const STORAGE_KEY_LAST_ORGANIZATION = 'last-organization'

export const BASE_URL = `${location.protocol}//${location.hostname}${
  location.port ? ':' + location.port : ''
}`

export const PIWIK_CONTAINER_URL = 'https://businessfinland.containers.piwik.pro'
export const PIWIK_CONTAINER_ID = '9b59d8a8-2f55-4357-9bb3-dd260b3a83b1'

export const DEFAULT_SERVICE_NAME = 'Business Finland ID'
export const DEFAULT_SERVICE_ID = '0000'
export const DEFAULT_SERVICE: IServiceEntity = {
  id: DEFAULT_SERVICE_ID,
  name: DEFAULT_SERVICE_NAME,
  redirection: {
    id: DEFAULT_SERVICE_ID,
    url: `${BASE_URL}/portal`,
    preferences: {
      requiredAuthLevel: 'L3',
      requireOrgSelection: false,
    },
  },
  returnUrl: {
    id: DEFAULT_SERVICE_ID,
    url: `${BASE_URL}/portal`,
  },
}

export const PREFERENCES_SCRIPT_URL = process.env['REACT_APP_PREFERENCES_SCRIPT_URL'] ?? ''

export const SUOMI_FI_LINKS: { [key: string]: string } = {
  fi: 'https://www.suomi.fi/ohjeet-ja-tuki/tunnistus',
  sv: 'https://www.suomi.fi/anvisningar-och-stod/identifikation',
  en: 'https://www.suomi.fi/instructions-and-support/identification',
}
