/* eslint-disable unicorn/no-null */
import classNames from 'classnames'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ColorEnum, FontWeight, Spacing } from '../../shared/enums'
import Icon from '../Icon'
import VisuallyHidden from '../VisuallyHidden'
import IDataGrid, {
  IDataGridAction,
  IDataGridBody,
  IDataGridCell,
  IDataGridColumn,
  IDataGridFooter,
  IDataGridHeader,
  IDataGridRow,
} from './DataGrid.interface'

/**
 * FOOTER
 */

const StyledDataGridFooter = styled.div<IDataGridFooter>`
  line-height: 2.75rem;
  min-height: 2.75rem;
`

const DataGridFooter: FunctionComponent<IDataGridFooter> = ({ children, ...rest }) => {
  return (
    <StyledDataGridFooter {...rest}>
      <tr>{children}</tr>
    </StyledDataGridFooter>
  )
}

/**
 * CELL
 */

const StyledDataGridCell = styled.td<IDataGridCell>`
  padding: ${Spacing.Small};
  border-bottom: 2px solid ${ColorEnum.bfGray};

  &.actions {
    width: 3rem;
    white-space: nowrap;
    text-align: center;
    & button {
      width: 100%;
    }
  }
`

const DataGridCell: FunctionComponent<IDataGridCell> = ({ children, ...rest }) => {
  return <StyledDataGridCell {...rest}>{children}</StyledDataGridCell>
}

/**
 * HEADER
 */

const StyledDataGridHeader = styled.thead<IDataGridHeader>`
  border-bottom: 2px solid ${ColorEnum.bfBlue};
`

const DataGridHeader: FunctionComponent<IDataGridHeader> = ({ children, ...rest }) => {
  return (
    <StyledDataGridHeader {...rest}>
      <tr>{children}</tr>
    </StyledDataGridHeader>
  )
}

/**
 * COLUMN
 */

const StyledDataGridColumn = styled.th<IDataGridColumn>`
  font-weight: ${FontWeight.Bold};
  padding: ${Spacing.Small};
`

const DataGridColumn: FunctionComponent<IDataGridColumn> = ({ name, ...rest }) => {
  return (
    <StyledDataGridColumn scope="col" {...rest}>
      {name}
    </StyledDataGridColumn>
  )
}

/**
 * BODY
 */

const StyledDataGridBody = styled.tbody<IDataGridBody>``

const DataGridBody: FunctionComponent<IDataGridBody> = ({ children, ...rest }) => {
  return <StyledDataGridBody {...rest}>{children}</StyledDataGridBody>
}

/**
 * ROW
 */

const StyledDataGridRow = styled.tr<IDataGridRow>`
  height: 2rem !important;

  &:hover {
    background-color: ${ColorEnum.bfLightBlue20};
  }
`

const DataGridRow: FunctionComponent<IDataGridRow> = ({
  selected,
  children,
  className,
  ...rest
}) => {
  const computedClass = classNames(
    'bfsso-data-grid-row',
    {
      selected,
    },
    className
  )

  return (
    <StyledDataGridRow className={computedClass} {...rest}>
      {children}
    </StyledDataGridRow>
  )
}

/**
 * ACTION
 */

const StyledDataGridAction = styled.button`
  color: ${ColorEnum.bfBlue};
  & svg {
    fill: ${ColorEnum.bfBlue};
  }
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: ${Spacing.Small};

  &.positive {
    color: ${ColorEnum.bfGreen};
    & svg {
      fill: ${ColorEnum.bfGreen};
    }
  }

  &.negative {
    color: ${ColorEnum.bfRed};
    & svg {
      fill: ${ColorEnum.bfRed};
    }
  }

  &:disabled {
    color: ${ColorEnum.bfGray};
    pointer-events: none;
    & svg {
      fill: ${ColorEnum.bfGray};
    }
  }
`

const DataGridAction: FunctionComponent<IDataGridAction> = ({
  label,
  onClick,
  icon,
  iconOnly,
  ...rest
}) => {
  return (
    <StyledDataGridAction className="bfsso-data-grid-action" onClick={onClick} {...rest}>
      {!!icon && <Icon name={icon} size="small" />} {!iconOnly && label}{' '}
      {!!iconOnly && <VisuallyHidden>{label}</VisuallyHidden>}
    </StyledDataGridAction>
  )
}

/**
 * CAPTION
 */

const DataGridCaption = styled.caption``

/**
 * DATA GRID
 */

const StyledDataGrid = styled.table``

const DataGrid: FunctionComponent<IDataGrid> & {
  Header: FunctionComponent<IDataGridHeader>
  Column: FunctionComponent<IDataGridColumn>
  Body: FunctionComponent<IDataGridBody>
  Row: FunctionComponent<IDataGridRow>
  Cell: FunctionComponent<IDataGridCell>
  Footer: FunctionComponent<IDataGridFooter>
  Action: FunctionComponent<IDataGridAction>
} = ({ caption, children }) => {
  return (
    <StyledDataGrid className="bfsso-data-grid">
      {!!caption && <DataGridCaption>{caption}</DataGridCaption>}
      {children}
    </StyledDataGrid>
  )
}

DataGrid.Header = DataGridHeader
DataGrid.Column = DataGridColumn
DataGrid.Body = DataGridBody
DataGrid.Row = DataGridRow
DataGrid.Cell = DataGridCell
DataGrid.Footer = DataGridFooter
DataGrid.Action = DataGridAction

export default DataGrid
