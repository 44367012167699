import classNames from 'classnames'
import { Children, FunctionComponent, Ref, forwardRef, isValidElement } from 'react'
import styled from 'styled-components'

import { ColorEnum } from '../../shared/enums'
import IButton from '../Button/Button.interface'
import Icon from '../Icon'
import VisuallyHidden from '../VisuallyHidden'
import IDropdown from './Dropdown.interface'

const BUTTON_HEIGHT = '44px'

const StyledDropdown = styled.div`
  position: relative;
`

const DropdownButton = styled.button`
  cursor: pointer;
  height: ${BUTTON_HEIGHT};

  &:hover {
    color: ${ColorEnum.bfBlue};
    & svg {
      fill: ${ColorEnum.bfBlue};
    }
  }

  &.open {
    color: ${ColorEnum.bfBlue};
    & svg {
      fill: ${ColorEnum.bfBlue};
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    color: ${ColorEnum.bfGray};
    & svg {
      fill: ${ColorEnum.bfGray};
    }
  }
`

const DropdownMenu = styled.ul`
  background-color: ${ColorEnum.white};
  padding: 8px 0;
  position: absolute;
  z-index: 9000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &.anchor-top-left {
    top: ${BUTTON_HEIGHT};
    left: 0;
  }
  &.anchor-bottom-left {
    bottom: ${BUTTON_HEIGHT};
    left: 0;
  }
  &.anchor-top-right {
    top: ${BUTTON_HEIGHT};
    right: 0;
  }
  &.anchor-bottom-right {
    bottom: ${BUTTON_HEIGHT};
    right: 0;
  }

  & > li {
    line-height: 2rem;
    height: 2rem;
  }

  & button {
    line-height: 2rem;
    height: 2rem;
    text-transform: none;
    border: none;
    padding: 0 1rem;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
`

const Dropdown: FunctionComponent<IDropdown> = forwardRef(
  (
    { icon, iconOnly, label, isOpen = false, anchor = 'top-left', onClick, disabled, children },
    forwardedRef: Ref<HTMLDivElement>
  ) => {
    const buttonClassName = classNames('bfsso-dropdown', { open: isOpen })

    return (
      <StyledDropdown ref={forwardedRef}>
        <DropdownButton className={buttonClassName} onClick={onClick} disabled={disabled}>
          {icon ? <Icon name={icon} size="smallest" /> : undefined}
          {!iconOnly ? label : <VisuallyHidden>{label}</VisuallyHidden>}
        </DropdownButton>
        {isOpen ? (
          <DropdownMenu className={`anchor-${anchor}`}>
            {Children.map(children, child => {
              return isValidElement<IButton>(child) ? <li>{child}</li> : undefined
            })}
          </DropdownMenu>
        ) : undefined}
      </StyledDropdown>
    )
  }
)

Dropdown.displayName = 'Dropdown'

export default Dropdown
