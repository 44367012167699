import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'

import services from '../api/services'
import { DEFAULT_SERVICE, QUERY_KEY_SERVICE_INFORMATION } from '../constants'
import { IServiceEntity } from '../shared/interfaces'

type UseServiceType = {
  isLoading: boolean
  service: IServiceEntity
}

const useService = () => {
  try {
    const [searchParams] = useSearchParams()

    const serviceId = searchParams.get('serviceId')
    const redirectUrl = searchParams.get('redirectUrl')

    const { data, status, refetch } = useQuery(
      [QUERY_KEY_SERVICE_INFORMATION],
      () =>
        !!serviceId &&
        services.getServiceInfo(serviceId, redirectUrl ? encodeURIComponent(redirectUrl) : null),
      {
        enabled: false,
      }
    )

    if (serviceId) {
      refetch()
    } else {
      return {
        isLoading: false,
        service: DEFAULT_SERVICE as IServiceEntity,
      } as UseServiceType
    }

    if (status === 'loading') return { isLoading: true, service: undefined }

    if (status === 'error' || data === undefined) throw new Error('Could not fetch service')

    return { isLoading: false, service: data.value as IServiceEntity } as UseServiceType
  } catch (error) {
    console.error(error)

    return { isLoading: false, service: DEFAULT_SERVICE as IServiceEntity } as UseServiceType
  }
}

export default useService
