import { compose } from '@reduxjs/toolkit'
import { useQuery } from '@tanstack/react-query'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Navigate } from 'react-router-dom'
import styled from 'styled-components'

import invitations from '../../api/invitations'
import meApi from '../../api/me'
import Container from '../../components/Container'
import {
  DEFAULT_SERVICE_ID,
  QUERY_KEY_MY_RECEIVED_INVITATIONS,
  QUERY_KEY_RECEIVED_REQUESTS,
  QUERY_KEY_USER_PROFILE,
  SESSION_KEY_SERVICE,
} from '../../constants'
import withAuth from '../../hocs/withAuth'
import withL3Access from '../../hocs/withL3Access'
import useService from '../../hooks/useService'
import { setNotifications, setUserInfo } from '../../redux/slices/meSlice'
import { RootState } from '../../redux/store'
import { Breakpoint, ColorEnum, Spacing } from '../../shared/enums'
import { INotification } from '../../shared/types'
import HeaderContainer from '../HeaderContainer'
import RouteWrapper from '../RouteWrapper'
import IPortalRouteWrapper from './PortalRouteWrapper.interface'

export const PortalWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Layout = styled.div`
  width: ${Breakpoint.ExtraLarge};
  height: auto;
  display: flex;
  flex-direction: row;
  padding: ${Spacing.ExtraLarge} ${Spacing.ExtraLarge};
  color: ${ColorEnum.bfBlue};
  gap: ${Spacing.ExtraLarge};

  @media (max-width: ${Breakpoint.ExtraLarge}) {
    width: 100%;
    height: 100%;
    padding: ${Spacing.ExtraLarge};
    flex-direction: column;
  }
`

const Sidebar = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${Breakpoint.Large}) {
    width: 100%;
    height: 100%;
  }
`

const Content = styled(Container)`
  width: 100%;
  max-width: 824px;
  display: flex;
  flex-direction: column;
  padding: 0;
  @media (max-width: ${Breakpoint.Large}) {
    height: 100%;
    margin-top: ${Spacing.Large};
  }

  @media (max-width: ${Breakpoint.ExtraLarge}) {
    max-width: 100%;
  }
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 100%;
  @media (max-width: ${Breakpoint.Large}) {
    width: 100%;
  }
`

const Ul = styled.ul`
  list-style: none;
`

const Li = styled.li`
  display: flex;
  height: 50px;
  width: 280px;
  cursor: pointer;
  align-items: center;
  color: black;
  padding-left: 21px;
  @media (max-width: ${Breakpoint.Small}) {
    width: 100% !important;
  }

  &:has(> a.active) {
    background-color: ${ColorEnum.bfLightGray};
    color: ${ColorEnum.bfBlue};
    border-left: 2px solid ${ColorEnum.bfBlue};
    padding-left: 19px;
    div {
      font-weight: 600;
    }

    & > a {
      text-decoration: none;
      font-weight: bold;
      color: ${ColorEnum.bfBlue};
    }
  }

  & > a {
    color: ${ColorEnum.black};
  }
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Large};
  flex-grow: 1;

  & > .bfsso-container {
    padding: 0 !important;
  }
`

const PortalRouteWrapper: FunctionComponent<IPortalRouteWrapper> = ({ title, children }) => {
  const { t: translate } = useTranslation()
  const { isLoading, service } = useService()
  const dispatch = useDispatch()

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const hasRegistered = useSelector((state: RootState) => state.me.uid)

  const userInfoQuery = useQuery([QUERY_KEY_USER_PROFILE], meApi.getMyUserInformation)
  const receivedRequestsQuery = useQuery([QUERY_KEY_RECEIVED_REQUESTS], meApi.getMyReceivedRequests)
  const receivedInvitationsQuery = useQuery(
    [QUERY_KEY_MY_RECEIVED_INVITATIONS],
    invitations.getReceivedInvitations
  )

  const [notificationCount, setNotificationCount] = useState(0)

  useEffect(() => {
    if (!isLoading && service && service.id !== DEFAULT_SERVICE_ID) {
      sessionStorage.setItem(SESSION_KEY_SERVICE, JSON.stringify(service))
    }
  }, [isLoading, service])

  useEffect(() => {
    if (
      receivedRequestsQuery.status === 'success' &&
      receivedInvitationsQuery.status === 'success'
    ) {
      const combinedNotifications: Array<INotification> = []

      if (!receivedRequestsQuery.error && receivedRequestsQuery.data)
        combinedNotifications.push(...receivedRequestsQuery.data)

      if (!receivedInvitationsQuery.error && receivedInvitationsQuery.data)
        combinedNotifications.push(...receivedInvitationsQuery.data)

      setNotificationCount(combinedNotifications.length)
      dispatch(setNotifications(combinedNotifications))
    }
  }, [
    dispatch,
    receivedInvitationsQuery.data,
    receivedInvitationsQuery.error,
    receivedInvitationsQuery.status,
    receivedRequestsQuery.data,
    receivedRequestsQuery.error,
    receivedRequestsQuery.status,
  ])

  useEffect(() => {
    if (userInfoQuery.status === 'success' && userInfoQuery.data) {
      dispatch(setUserInfo(userInfoQuery.data))
    }
  }, [dispatch, userInfoQuery.data, userInfoQuery.status])

  const reqLabel = translate('portal.requests')
  const reqLabelWithReqN = notificationCount === 0 ? reqLabel : `${reqLabel} (${notificationCount})`

  const navItems = useMemo(
    () => [
      {
        label: translate('portal.user-profile-link'),
        href: '/portal',
        end: true,
      },
      {
        label: translate('portal.organization-link'),
        href: '/portal/organization',
        end: false,
      },
      {
        label: reqLabelWithReqN,
        href: '/portal/notifications',
        end: false,
      },
      // eslint-disable-next-line etc/no-commented-out-code
      /*
      {
        label: translate('portal.newsletters'),
        href: '/portal/newsletters',
        end: false,
      },
      */
    ],
    [reqLabelWithReqN, translate]
  )

  const className = useCallback(
    ({ isActive }: { isActive: boolean }) => (isActive ? 'active' : undefined),
    []
  )

  const nav = useMemo(
    () => (
      <Nav>
        <Ul>
          {navItems.map(item => (
            <Li key={item.label}>
              <NavLink to={item.href} className={className} end={item.end}>
                {item.label}
              </NavLink>
            </Li>
          ))}
        </Ul>
      </Nav>
    ),
    [className, navItems]
  )

  if (!hasRegistered) return <Navigate to="/register/create-account" />

  return (
    <RouteWrapper title={title}>
      <HeaderContainer />
      <PortalWrapper>
        <Layout>
          <Sidebar>{nav}</Sidebar>
          <Content>
            <Main>{children}</Main>
          </Content>
        </Layout>
      </PortalWrapper>
    </RouteWrapper>
  )
}

export default compose(
  withAuth,
  withL3Access
)(PortalRouteWrapper) as FunctionComponent<IPortalRouteWrapper>
