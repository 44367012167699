import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ColorEnum } from '../../shared/enums'
import ErrorMessage from '../ErrorMessage'
import ICheckbox from './Checkbox.interface'

const StyledInput = styled.input`
  border: 1px solid ${ColorEnum.bfBlue};
  border-radius: 3px;
  min-width: 1.625rem;
  min-height: 1.625rem;
  background-color: ${props => props.checked && ColorEnum.bfBlue};

  &:hover {
    cursor: pointer;
  }
`

const StyledLabel = styled.label`
  display: flex;
  gap: 1rem;
  align-items: start;
`

const Checkbox: FunctionComponent<ICheckbox> = ({
  label,
  hasError,
  errorMessage,
  disabled,
  isRequired,
  ...rest
}) => {
  return (
    <StyledLabel className="bfsso-checkbox">
      <StyledInput type="checkbox" disabled={disabled} required={isRequired} {...rest} />
      {label}
      {hasError && !!errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : undefined}
    </StyledLabel>
  )
}

export default Checkbox
