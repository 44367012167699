export enum Breakpoint {
  Small = '576px',
  Medium = '768px',
  Large = '992px',
  ExtraLarge = '1200px',
}

export enum Spacing {
  None = '0',
  Smallest = '0.15rem',
  ExtraSmall = '0.25rem',
  Small = '0.5rem',
  Medium = '0.75rem',
  Large = '1rem',
  ExtraLarge = '2rem',
  Largest = '3rem',
}

export enum FontSize {
  Small = '0.9rem',
  Normal = '1rem',
  Large = '1.25rem',
  ExtraLarge = '1.5rem',
}

export enum FontWeight {
  Thin = '300',
  Normal = '400',
  Bold = '700',
}

export enum HeadingSize {
  H1 = '5.653rem',
  H2 = '3.998rem',
  H3 = '2.827rem',
  H4 = '1.999rem',
  H5 = '1.414rem',
  H6 = '1rem',
}

export enum ColorEnum {
  bfBlue = '#002EA2',
  bfBlueDark = '#002280',
  bfBlue20 = '#063ABF',
  bfLightBlue = '#F0F4FF',
  bfLightBlue20 = '#F5F9FF',
  bfDarkGray = '#343A40',
  bfLightGray = '#E7EBEF',
  bfGray = '#C7CACE',
  bfGreen = '#0B7724',
  bfDarkPink = '#C846A5',
  bfPink = '#EEC9E6',
  bfRed = '#C12D3B',
  bfYellow = '#FFC107',
  white = '#FFFFFF',
  black = '#191919',
}

export enum IconWidth {
  Smallest = '0.75rem',
  Small = '1rem',
  Medium = '2rem',
  Large = '5rem',
}

export enum IconHeight {
  Smallest = '0.75rem',
  Small = '1rem',
  Medium = '2rem',
  Large = '5rem',
}

export enum InputType {
  Text = 'text',
  TextArea = 'textarea',
  Number = 'number',
  Email = 'email',
  Password = 'password',
  Phone = 'tel',
  Hidden = 'hidden',
}

export enum AvatarSize {
  Small = '2rem',
  Medium = '4rem',
  Large = '6rem',
}

export enum UserRole {
  employee = 140_110_000,
  consultant = 140_110_001,
}

export enum NotificationType {
  Request = 'request',
  Invitation = 'invitation',
}
