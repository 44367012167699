import { ColorEnum, FontSize, FontWeight, Spacing } from '../shared/enums'
import {
  BackgroundColorProp,
  ColorProp,
  FontSizeProp,
  FontWeightProp,
  GapProp,
  Size,
  SpacingProp,
} from '../shared/types'

// TODO: Move values to an object in `tokens.ts` file at `src/`
export const colorPropToHex = (colorProp: ColorProp | BackgroundColorProp) => {
  switch (colorProp) {
    case 'black':
      return ColorEnum.black
    case 'white':
      return ColorEnum.white
    case 'bf-red':
      return ColorEnum.bfRed
    case 'bf-blue':
      return ColorEnum.bfBlue
    case 'bf-light-blue':
      return ColorEnum.bfLightBlue
    case 'bf-light-gray':
      return ColorEnum.bfLightGray
    case 'bf-green':
      return ColorEnum.bfGreen
  }
}

export const fontSizePropToRem = (size: FontSizeProp) => {
  switch (size) {
    case 'small':
      return FontSize.Small
    case 'normal':
      return FontSize.Normal
    case 'large':
      return FontSize.Large
    case 'extra-large':
      return FontSize.ExtraLarge
  }
}

export const fontWeightPropToValue = (weight: FontWeightProp) => {
  switch (weight) {
    case 'thin':
      return FontWeight.Thin
    case 'normal':
      return FontWeight.Normal
    case 'bold':
      return FontWeight.Bold
  }
}

export const gapToRem = (gap: GapProp) => {
  const sizeMap = {
    none: Spacing.None,
    smallest: Spacing.Smallest,
    'extra-small': Spacing.ExtraSmall,
    small: Spacing.Small,
    medium: Spacing.Medium,
    large: Spacing.Large,
    'extra-large': Spacing.ExtraLarge,
    largest: Spacing.Largest,
  }

  const spacingArray = gap.split(' ')
  const valuesArray = spacingArray.reduce((values: Array<string>, current: string) => {
    const value = sizeMap[current as Size]

    values.push(value)

    return values
  }, [])

  return valuesArray.join(' ')
}

export const spacingPropToRem = (spacing: SpacingProp) => {
  const sizeMap = {
    none: Spacing.None,
    smallest: Spacing.Smallest,
    'extra-small': Spacing.ExtraSmall,
    small: Spacing.Small,
    medium: Spacing.Medium,
    large: Spacing.Large,
    'extra-large': Spacing.ExtraLarge,
    largest: Spacing.Largest,
  }

  const spacingArray = spacing.split(' ')
  const valuesArray = spacingArray.reduce((values: Array<string>, current: string) => {
    const value = sizeMap[current as Size]

    values.push(value)

    return values
  }, [])

  return valuesArray.join(' ')
}
