import { BASE_URL } from '../constants'

/**
 * This function validates finnish businessId. Pattern is 7 digits, dash and 1 digit.
 * @param businessID
 * @returns {boolean}
 */
export const validateFinnishBusinessID = (businessID: string): boolean => {
  const pattern = /^(\d{7}-\d)$/

  return pattern.test(businessID)
}

export const hasThreeConsecutive = (password: string, email: string) => {
  for (let i = 0; i < password.length - 2; i++) {
    if (email.includes(password.slice(i, i + 3))) {
      return true
    }
  }

  return false
}

/**
 * Handles validation of service return url and navigation
 */
export const validateServiceReturnUrl = async (service: string, href: string) => {
  try {
    const validationEndpoint = new URL(`/api/services/${service}/return`, BASE_URL)

    const returnUrl = new URL(href)

    validationEndpoint.searchParams.append('returnUrl', returnUrl.toString())

    const res = await fetch(validationEndpoint.toString())
    const data = await res.json()

    if (data.returnUrl) return data.returnUrl
  } catch (error) {
    console.error(error)
  }
}

/**
 * Checks if name is valid. This checks if the name is not empty, contains only valid letters and special characters like "-",
 * and rejects numbers or mathematical symbols.
 * Supports accented characters and diacritics.
 * @param name
 * @returns {boolean}
 */
export function isValidName(name: string): boolean {
  if (!name) {
    return false
  }

  // Regular expression to match letters (including accented ones), spaces, apostrophes, and dashes.
  const namePattern = /^[\s'A-Za-zÀ-ÖØ-öø-žẀ-ỿ-]+$/

  return name.trim().length > 0 && namePattern.test(name.trim())
}
