import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { colorPropToHex, gapToRem, spacingPropToRem } from '../../utils/style-utils'
import IContainer from './Container.interface'

const StyledContainer = styled.div<IContainer>(props => ({
  backgroundColor: props.backgroundColor ? colorPropToHex(props.backgroundColor) : 'inherit',
  color: props.color ? colorPropToHex(props.color) : 'inherit',
  margin: props.margin ? spacingPropToRem(props.margin) : '0',
  padding: props.padding ? spacingPropToRem(props.padding) : '2rem',
  width: '100%',
  textAlign: props.textAlign ?? 'left',
  display: props.display ?? 'flex',
  flexDirection: props.flexDirection ?? 'column',
  justifyContent: props.justifyContent ?? 'normal',
  alignItems: props.alignItems ?? 'normal',
  gap: props.gap ? gapToRem(props.gap) : '2rem',
}))

const Component: FunctionComponent<IContainer> = props => (
  <StyledContainer className="bfsso-container" {...props} />
)

export default Component
