import { Children, FunctionComponent, createElement, isValidElement } from 'react'
import styled from 'styled-components'

import { Spacing } from '../../shared/enums'
import Checkbox from '../Checkbox/Checkbox'
import ICheckbox from '../Checkbox/Checkbox.interface'
import ErrorMessage from '../ErrorMessage'
import FieldLabel from '../FieldLabel'
import ICheckboxList from './CheckboxList.interface'

const StyledFieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const CheckboxList: FunctionComponent<ICheckboxList> = ({
  name,
  label,
  children,
  hasError,
  errorMessage,
}) => {
  return (
    <StyledFieldSet name={name}>
      <FieldLabel as="legend" style={{ marginBottom: Spacing.Small }}>
        {label}
      </FieldLabel>
      {Children.map(children, child => {
        return isValidElement<ICheckbox>(child)
          ? createElement(Checkbox, {
              ...child.props,
            })
          : undefined
      })}
      {hasError && !!errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : undefined}
    </StyledFieldSet>
  )
}

export default CheckboxList
