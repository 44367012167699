/**
 * THIS FILE EXISTS AS A PLACEHOLDER FOR FUNCTIONALITIES THAT
 * ALLOW EDITING AND SORTING THE DATA IN THE PRESENTATIONAL
 * DATA GRID COMPONENT!
 */
import { FunctionComponent } from 'react'

import DataGrid from '../../components/DataGrid'
import IDataGrid, {
  IDataGridAction,
  IDataGridBody,
  IDataGridCell,
  IDataGridColumn,
  IDataGridFooter,
  IDataGridHeader,
  IDataGridRow,
} from '../../components/DataGrid/DataGrid.interface'

const Footer: FunctionComponent<IDataGridFooter> = props => {
  return <DataGrid.Footer {...props} />
}

const Row: FunctionComponent<IDataGridRow> = props => {
  return <DataGrid.Row {...props} />
}

const Cell: FunctionComponent<IDataGridCell> = props => {
  return <DataGrid.Cell {...props} />
}

const Header: FunctionComponent<IDataGridHeader> = props => {
  return <DataGrid.Header {...props} />
}

const Column: FunctionComponent<IDataGridColumn> = props => {
  return <DataGrid.Column {...props} />
}

const Body: FunctionComponent<IDataGridBody> = props => {
  return <DataGrid.Body {...props} />
}

const Action: FunctionComponent<IDataGridAction> = props => {
  return <DataGrid.Action {...props} />
}

const DataGridContainer: FunctionComponent<IDataGrid> & {
  Header: FunctionComponent<IDataGridHeader>
  Column: FunctionComponent<IDataGridColumn>
  Body: FunctionComponent<IDataGridBody>
  Row: FunctionComponent<IDataGridRow>
  Cell: FunctionComponent<IDataGridCell>
  Footer: FunctionComponent<IDataGridFooter>
  Action: FunctionComponent<IDataGridAction>
} = ({ caption, children }) => {
  return <DataGrid caption={caption}>{children}</DataGrid>
}

DataGridContainer.Header = Header
DataGridContainer.Column = Column
DataGridContainer.Body = Body
DataGridContainer.Row = Row
DataGridContainer.Cell = Cell
DataGridContainer.Footer = Footer
DataGridContainer.Action = Action

export default DataGridContainer
