import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type SpinnerState = {
  visible: boolean
}

const initialState: SpinnerState = {
  visible: false,
}

export const spinnerSlice = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    setSpinner: (
      state: SpinnerState,
      action: PayloadAction<{
        visible: boolean
      }>
    ) => {
      state.visible = action.payload.visible
    },
  },
})

export const selectSpinner = (state: { spinner: SpinnerState }) => state.spinner.visible

export const { setSpinner } = spinnerSlice.actions

export default spinnerSlice.reducer
