import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

import { ColorEnum, Spacing } from '../../shared/enums'

type Props = {
  children: ReactNode
}

const Main = styled.main`
  width: 100%;
  background-color: ${ColorEnum.white};
  color: ${ColorEnum.black};
`

const ContentWrapper = styled.div`
  max-width: 44.625rem;
  margin: auto;
  padding: 0 ${Spacing.ExtraLarge};
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const CenteredLayout: FunctionComponent<Props> = props => {
  const { children } = props

  return (
    <Main>
      <ContentWrapper>{children}</ContentWrapper>
    </Main>
  )
}

export { CenteredLayout }
