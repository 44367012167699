import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Heading from '../../components/Heading/Heading'
import { DEFAULT_SERVICE_ID, SESSION_KEY_SERVICE } from '../../constants'
import HeaderContainer from '../../containers/HeaderContainer/HeaderContainer'
import RouteWrapper from '../../containers/RouteWrapper/RouteWrapper'
import { ColorEnum, Spacing } from '../../shared/enums'
import { IServiceEntity } from '../../shared/interfaces'
import Container from './../../components/Container'

const Main = styled.main`
  width: 100%;
  background-color: ${ColorEnum.white};
  color: ${ColorEnum.black};
`

const ContentWrapper = styled.div`
  max-width: 44.625rem;
  margin: auto;
  padding: ${Spacing.ExtraLarge};
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const CanceledLogin: FunctionComponent = () => {
  const { t: translate } = useTranslation()
  const navigate = useNavigate()

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getRedirectUrl = () => {
    const defaultUrl = '/'
    const storedService = sessionStorage.getItem(SESSION_KEY_SERVICE)

    if (!storedService) {
      return defaultUrl
    }

    const service: IServiceEntity = JSON.parse(storedService)

    if (service.id !== DEFAULT_SERVICE_ID) {
      return `/?serviceId=${service.id}`
    }

    return defaultUrl
  }

  const redirectUrl = getRedirectUrl()

  setTimeout(() => {
    navigate(redirectUrl)
  }, 5000)

  return (
    <RouteWrapper title={translate('canceled-login.title')}>
      <HeaderContainer />
      <ContentWrapper>
        <Heading level="h1" visualLevel="h4" color="bf-blue">
          {translate('canceled-login.title')}
        </Heading>
        <Main>
          <Container gap="extra-large" padding="none" color="bf-blue">
            <p>{translate('canceled-login.tip')}</p>
          </Container>
        </Main>
      </ContentWrapper>
    </RouteWrapper>
  )
}

export default CanceledLogin
