/* eslint-disable unicorn/prefer-query-selector */
import { FunctionComponent } from 'react'
import ModalComponent from 'react-modal'
import styled from 'styled-components'

import { ColorEnum } from '../../shared/enums'
import IModal from './Modal.interface'

const ModalContainer = styled(ModalComponent)`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
`
const InnerContainer = styled.div`
  width: 90vw;
  max-width: 40.625rem;
  min-height: 200px;
  background-color: ${ColorEnum.white};
  padding: 2rem;
  color: ${ColorEnum.bfBlue};
`

const Modal: FunctionComponent<IModal> = ({ children, isOpen, ariaLabel, ...rest }) => {
  return (
    <ModalContainer
      isOpen={isOpen}
      appElement={document.getElementById('root') as HTMLElement}
      contentLabel={ariaLabel}
      {...rest}
    >
      <InnerContainer>{children}</InnerContainer>
    </ModalContainer>
  )
}

export default Modal
