import { z } from 'zod'

import { PasswordSchema } from '../../../../routes/Register/AccountCreation/RegistrationFormStep/RegistrationForm.schema'

// #region Validation
export const ChangePasswordValidation = z
  .object({
    newPassword: PasswordSchema,
    confirm: PasswordSchema,
  })
  .superRefine((data, ctx) => {
    if (data.newPassword !== data.confirm) {
      ctx.addIssue({
        path: ['confirm'],
        message: 'account-creation.form-passwordsmustmatch',
        code: z.ZodIssueCode.custom,
      })
    }
  })
// #endregion

//#region Types
export type ChangePasswordFormValues = z.infer<typeof ChangePasswordValidation>
//#endregion
