import classNames from 'classnames'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { ColorEnum, Spacing } from '../../shared/enums'
import Icon from '../Icon'
import VisuallyHidden from '../VisuallyHidden'
import IButton from './Button.interface'

const StyledButton = styled.button<{ iconOnly?: boolean }>`
  padding: 0.75rem 1rem;
  color: ${ColorEnum.white};
  background-color: ${ColorEnum.bfBlue};
  border: 1px ${ColorEnum.bfBlue} solid;
  text-transform: uppercase;
  min-width: 8rem;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    fill: ${ColorEnum.white};

    &.icon-left {
      margin-right: ${Spacing.Small};
    }
    &.icon-right {
      margin-left: ${Spacing.Small};
    }
  }

  &:hover,
  &:active {
    transition: background-color 0.25s ease;
    color: ${ColorEnum.bfBlue};
    background-color: ${ColorEnum.white};
    cursor: pointer;

    & svg {
      fill: ${ColorEnum.bfBlue};
    }
  }

  &:disabled {
    pointer-events: none;
    background-color: ${ColorEnum.bfDarkGray};
    color: ${ColorEnum.white};
    border-color: ${ColorEnum.bfDarkGray};
  }

  &:focus {
    color: ${ColorEnum.white};
    background-color: ${ColorEnum.black};
    border-color: ${ColorEnum.black};

    & svg {
      fill: ${ColorEnum.white};
    }
  }

  &.plain {
    color: ${ColorEnum.bfBlue};
    background-color: rgba(0, 0, 0, 0);
    border: none;

    & svg {
      fill: ${ColorEnum.bfBlue};
    }

    &:hover,
    &:active {
      color: ${ColorEnum.white};
      background-color: ${ColorEnum.bfBlue};

      & svg {
        fill: ${ColorEnum.white};
      }
    }

    &:disabled {
      color: ${ColorEnum.bfDarkGray};

      & svg {
        fill: ${ColorEnum.bfDarkGray};
      }
    }
  }

  &.secondary {
    color: ${ColorEnum.bfBlue};
    background-color: ${ColorEnum.white};
    border: 1px ${ColorEnum.bfBlue} solid;

    & svg {
      fill: ${ColorEnum.bfBlue};
    }

    &:hover,
    &:active {
      color: ${ColorEnum.white};
      background-color: ${ColorEnum.bfBlue};

      & svg {
        fill: ${ColorEnum.white};
      }
    }

    &:disabled {
      background-color: ${ColorEnum.white};
      color: ${ColorEnum.bfDarkGray};

      & svg {
        fill: ${ColorEnum.bfDarkGray};
      }
    }
  }

  &.negative-plain {
    color: ${ColorEnum.bfRed};
    background-color: rgba(0, 0, 0, 0);
    border: none;

    & svg {
      fill: ${ColorEnum.bfRed};
    }

    &:hover,
    &:active {
      color: ${ColorEnum.white};
      background-color: ${ColorEnum.bfRed};

      & svg {
        fill: ${ColorEnum.white};
      }
    }

    &:disabled {
      color: ${ColorEnum.bfDarkGray};

      & svg {
        fill: ${ColorEnum.bfDarkGray};
      }
    }
  }
  
  &.text-button {
    background-color: transparent;
    padding: 2px 8px !important;
    border: 0;
    text-transform: capitalize;
    text-decoration: underline;
    color: black !important;
    width: auto !important;
    min-width: auto !important;

    & svg {
      fill: black !important;
    }

  &.negative {
    color: ${ColorEnum.white};
    background-color: ${ColorEnum.bfRed};
    border: 1px solid ${ColorEnum.bfRed};

    & svg {
      fill: ${ColorEnum.white};
    }

    &:hover,
    &:active {
      color: ${ColorEnum.bfRed};
      background-color: ${ColorEnum.white};

      & svg {
        fill: ${ColorEnum.bfRed};
      }
    }

    &:disabled {
      background-color: ${ColorEnum.white};
      color: ${ColorEnum.bfDarkGray};

      & svg {
        fill: ${ColorEnum.bfDarkGray};
      }
    }
  }

  ${props =>
    props.iconOnly &&
    css`
      min-width: initial;
      padding: 0.75rem 1.5rem;

      &::before,
      &::after {
        box-sizing: border-box;
      }

      & svg {
        margin-right: 0 !important;
      }
    `}
`

const Button: FunctionComponent<IButton> = ({
  onClick,
  label,
  icon,
  iconRight,
  iconOnly,
  variant = 'primary',
  type = 'button',
  disabled,
  iconSize,
}) => {
  const computedClass = classNames('bfsso-button', variant)

  return (
    <StyledButton
      className={computedClass}
      aria-label={label}
      onClick={onClick}
      type={type}
      disabled={disabled}
      iconOnly={iconOnly}
    >
      {icon && !iconRight ? (
        <Icon name={icon} size={(iconSize !== null && iconSize) || 'small'} className="icon-left" />
      ) : undefined}
      {iconOnly ? <VisuallyHidden>{label}</VisuallyHidden> : label}
      {icon && iconRight ? (
        <Icon
          name={icon}
          size={(iconSize !== null && iconSize) || 'small'}
          className="icon-right"
        />
      ) : undefined}
    </StyledButton>
  )
}

export default Button
