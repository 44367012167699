import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import organizations from '../../api/organizations'
import { IAccountEssentials } from '../../shared/interfaces'

interface OrganizartionsSearchState {
  loading: boolean
  organizations: IAccountEssentials
}

const initialState: OrganizartionsSearchState = {
  loading: false,
  organizations: {},
}

export const loadOrganization = createAsyncThunk(
  'organizationSearch/loadOrganization',
  async (businessId: string) => {
    return await organizations.getCrmOrganizationByBusinessId(businessId)
  }
)

export const organizationSearchSlice = createSlice({
  name: 'organizationSearch',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadOrganization.pending, state => {
      state.loading = true
      state.organizations = {}
    })
    builder.addCase(loadOrganization.fulfilled, (state, action) => {
      state.loading = false
      state.organizations = action.payload
    })
    builder.addCase(loadOrganization.rejected, state => {
      state.loading = false
    })
  },
})

export const selectOrganizations = (state: OrganizartionsSearchState) => {
  return state.organizations
}

export default organizationSearchSlice.reducer
