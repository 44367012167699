import styled from 'styled-components'

import { Heading4 } from '../../components/Heading/Heading.style'
import { ColorEnum, FontSize, FontWeight } from '../../shared/enums'

export const StyledMainContainer = styled.main`
  background-color: ${ColorEnum.bfBlue};
`

export const StyledTitle3 = styled.h3`
  width: 650px;
  color: ${ColorEnum.black};
  margin: 0;
  font-weight: ${FontWeight.Bold};
  text-transform: unset;
  font-size: ${FontSize.ExtraLarge};
`

export const StyledTitle4 = styled(Heading4)`
  color: ${ColorEnum.bfBlue};
  text-transform: unset;
  margin-top: 2rem;
  margin-bottom: 1rem;
`

export const StyledParagraph16 = styled.p`
  font-size: ${FontSize.Normal};
  line-height: 2;
`

export const StyledBox = styled.div`
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  color: ${ColorEnum.bfBlue};
  padding: 2rem;

  @media (min-width: 768px) {
    padding: 2rem 0;
  }
`

export const StyledContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: ${ColorEnum.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-left: 2rem;
`

export const StyledFormContainer = styled.div`
  width: 100%;
  background-color: ${ColorEnum.bfLightBlue};
  margin-top: 2rem;
  padding: 2rem;
  color: ${ColorEnum.black};
`
