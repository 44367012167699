// Global theme
// Theme specification: https://styled-system.com/theme-specification/
const theme = {
  breakpoints: [],
  colors: {},
  fonts: [],
  fontSizes: [],
  mediaQueries: {},
}

export default theme
