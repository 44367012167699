import { z } from 'zod'

import { ValidatePhone } from '../../../../routes/Register/AccountCreation/RegistrationFormStep/RegistrationForm.schema'
import { isValidName } from '../../../../utils/validation-utils'

export const BasicInformationValidation = z.object({
  firstname: z
    .string()
    .min(1)
    .max(50)
    .refine(firstname => {
      return isValidName(firstname)
    }),
  lastname: z
    .string()
    .min(1)
    .max(50)
    .refine(lastname => {
      return isValidName(lastname)
    }),
  phone: ValidatePhone.optional(),
  hetu: z.string().min(1).max(50).optional(),
})

export type BasicInformation = z.infer<typeof BasicInformationValidation>
