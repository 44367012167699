import { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router'

import HeaderContainer from '../../../containers/HeaderContainer'
import withAuth from '../../../hocs/withAuth'
import { RootState } from '../../../redux/store'

const Organization: FunctionComponent = () => {
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const hasRegistered = useSelector((state: RootState) => state.me.uid)

  if (!hasRegistered) return <Navigate to="/register/create-account" />

  return (
    <>
      <HeaderContainer />
      <Outlet />
    </>
  )
}

export default withAuth(Organization)
