import { AnchorHTMLAttributes, FunctionComponent } from 'react'
import { Link, To } from 'react-router-dom'
import styled from 'styled-components'

const StyledInternalLink = styled(Link)`
  cursor: pointer;
`

const InternalLink: FunctionComponent<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  href,
  className,
  children,
  ...rest
}) => {
  return (
    <StyledInternalLink className={`bfsso-internal-link ${className}`} to={href as To} {...rest}>
      {children}
    </StyledInternalLink>
  )
}

export default InternalLink
