import HttpError from '../errors/HttpError'
import { ACRValueValidation, ACRValues } from '../routes/Login/Authenticate/Authenticate.schema'

/**
 * This function initiates the authentication process. We check if the authLevel is valid and if it is not we throw an error.
 * @param authLevel
 * @param prompt
 * @returns {Promise<unknown>}
 */
const initiateAuthProcess = async (authLevel: ACRValues, prompt?: string) => {
  const isValidAuthLevel = ACRValueValidation.safeParse(authLevel)

  if (!isValidAuthLevel) {
    throw new HttpError(400, 'Invalid auth level')
  }

  let path = `/api/tokens/login?authLevel=${authLevel}`

  if (prompt) path += `&prompt=${prompt}`

  const response = await fetch(path, {
    credentials: 'include',
  })

  return response.json()
}

const initiateWeakAuthProcess = async () => {
  const path = '/api/tokens/loginWeakAuth'

  const response = await fetch(path, {
    credentials: 'include',
  })

  return response.json()
}

const initiateAfterRegisterAuthProcess = async () => {
  const path = '/api/tokens/loginAfterRegister'

  const response = await fetch(path, {
    credentials: 'include',
  })

  return response.json()
}

const initiateSilentLogin = async () => {
  const response = await fetch('/api/tokens/silent-login')

  return response.json()
}

const processAuthResponse = async (code: string, state: string) => {
  const pageUrl = `${window.location.origin}/sso?code=${code}&state=${state}` // TODO: Can we just use window.location.href?

  const response = await fetch('/api/tokens/processCallback', {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      pageUrl,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.json()
}

const getUserInfo = async () => {
  const response = await fetch('/api/tokens/userinfo')

  if (!response.ok) throw new HttpError(401, 'Unauthorized')

  return response.json()
}

const logOut = async () => {
  const response = await fetch('/api/tokens/logout', { method: 'POST' })

  return response.json()
}

const getPreferencesToken = async () => {
  const response = await fetch('/api/tokens/preferences', {
    credentials: 'include',
  })

  if (!response.ok) throw new HttpError(401, 'Unauthorized')

  return response.json()
}

export default {
  initiateAuthProcess,
  initiateWeakAuthProcess,
  initiateAfterRegisterAuthProcess,
  initiateSilentLogin,
  processAuthResponse,
  getUserInfo,
  logOut,
  getPreferencesToken,
}
