import axios, { AxiosResponse } from 'axios'

enum ValidationKeys {
  Email = 'VALIDATE_EMAIL',
}

export type ValidateEmailResponse = {
  errors: {
    field: string
    message: string
  }[]
  message: string
  details?: {
    email: string
  }
}

const validateEmail = async (email: string): Promise<AxiosResponse<ValidateEmailResponse>> => {
  return await axios.post('/api/validations/email', { email })
}

export { validateEmail, ValidationKeys }
