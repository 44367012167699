import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ColorEnum, Spacing } from '../../shared/enums'
import RouteWrapper from '../RouteWrapper'
import ILoginRouteWrapper from './LoginRouteWrapper.interface'

const Main = styled.main`
  width: 100%;
  background-color: ${ColorEnum.white};
  color: ${ColorEnum.black};
`

const ContentWrapper = styled.div`
  max-width: 44.625rem;
  margin: auto;
  padding: ${Spacing.ExtraLarge};
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const RegistrationRouteWrapper: FunctionComponent<ILoginRouteWrapper> = ({ title, children }) => {
  return (
    <RouteWrapper title={title}>
      <Main>
        <ContentWrapper>{children}</ContentWrapper>
      </Main>
    </RouteWrapper>
  )
}

export default RegistrationRouteWrapper
