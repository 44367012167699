import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  FunctionComponent,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import me from '../../../../api/me'
import Button from '../../../../components/Button'
import Container from '../../../../components/Container'
import Pagination from '../../../../components/Pagination'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import Text from '../../../../components/Text'
import { QUERY_KEY_LEAVE_ORGANIZATION, QUERY_KEY_USER_ORGANIZATIONS } from '../../../../constants'
import DataGridContainer from '../../../../containers/DataGridContainer'
import DropdownContainer from '../../../../containers/DropdownContainer'
import useL3Access from '../../../../hooks/useL3Access'
import usePagination from '../../../../hooks/usePagination'
import { setSpinner } from '../../../../redux/slices/spinnerSlice'
import { IOrganizationDetails } from '../../../../shared/interfaces'
import InvitationModal from '../../OrganizationDetails/OrganizationUsers/InvitationModal'

const UserOrganizations: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { t: translate } = useTranslation()
  const [userOrganizations, setUserOrganizations] =
    useState<Array<IOrganizationDetails> | undefined>()
  const hasL3Access = useL3Access()

  const [organizationId, setOrganizationId] = useState('')
  const [isModalOpen, setModalOpen] = useState(false)

  const queryClient = useQueryClient()
  const myOrganizationsQuery = useQuery([QUERY_KEY_USER_ORGANIZATIONS], me.getMyOrganizations)
  const leaveOrganizationMutation = useMutation({
    mutationKey: [QUERY_KEY_LEAVE_ORGANIZATION],
    mutationFn: (businessId: string) => me.leaveOrganization(businessId),
  })

  useEffect(() => {
    if (myOrganizationsQuery.status === 'success' && !!myOrganizationsQuery.data) {
      setUserOrganizations(myOrganizationsQuery.data)
    }
  }, [myOrganizationsQuery.data, myOrganizationsQuery.status])

  useEffect(() => {
    if (leaveOrganizationMutation.status === 'success') {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_USER_ORGANIZATIONS],
      })
      dispatch(setSpinner({ visible: false }))
      dispatch(openSnackbar(translate('snackbar.leave-organization-success'), false))
    } else if (leaveOrganizationMutation.status === 'error') {
      dispatch(setSpinner({ visible: false }))
      dispatch(openSnackbar(translate('snackbar.leave-organization-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveOrganizationMutation.status, queryClient])

  const organizationColumns = [
    { key: 'name', name: translate('organization.organizations-column-name') },
    {
      key: 'businessId',
      name: translate('organization.organizations-column-business-id'),
    },
    {
      key: 'actions',
      name: translate('microcopies.actions'),
    },
  ]

  const previousLabel = translate('microcopies.previous')
  const nextLabel = translate('microcopies.next')

  const { currentItems, pageCount, currentPage, setCurrentPage, needsPagination } = usePagination(
    userOrganizations || []
  )

  const organizationRows: Array<{ id: string; name: string; businessId: string }> =
    currentItems.reduce(
      (
        acc: Array<{ id: string; name: string; businessId: string }>,
        current: IOrganizationDetails
      ) => {
        const rowData = {
          id: current.id,
          name: current.name,
          businessId: current.businessId,
        }
        acc.push(rowData)

        return acc
      },
      []
    )

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleRemove = useCallback(
    (businessId: string) => (_event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      dispatch(setSpinner({ visible: true }))
      leaveOrganizationMutation.mutate(businessId)
    },
    [leaveOrganizationMutation, dispatch]
  )

  const handleOpenModal = useCallback((id: string) => {
    setOrganizationId(id)
    setModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
    setOrganizationId('')
  }, [])

  return (
    <Container gap="large">
      {!currentItems && <Text>{translate('organization.organizations-not-found')}</Text>}

      <DataGridContainer>
        <DataGridContainer.Header>
          {organizationColumns
            ? organizationColumns.map(col => {
                return <DataGridContainer.Column key={col.name} name={col.name} />
              })
            : undefined}
        </DataGridContainer.Header>
        <DataGridContainer.Body>
          {organizationRows
            ? organizationRows.map((row: { id: string; name: string; businessId: string }) => {
                const { id, ...rest } = row

                return (
                  <DataGridContainer.Row key={id}>
                    <>
                      {Object.values(rest).map((cell: ReactNode, cellIndex: number) => {
                        return (
                          <DataGridContainer.Cell key={cellIndex}>{cell}</DataGridContainer.Cell>
                        )
                      })}
                    </>
                    <DataGridContainer.Cell className="actions">
                      <DropdownContainer
                        label="&#8942;"
                        anchor="top-right"
                        icon="ellipsis"
                        iconOnly
                        disabled={!hasL3Access}
                      >
                        <Button
                          icon="plus"
                          label={translate('organization-details.add-user-label')}
                          onClick={() => handleOpenModal(rest.businessId)}
                          disabled={!hasL3Access}
                          variant="plain"
                        />
                        <Button
                          icon="close"
                          label={translate('organization.leave-organization')}
                          onClick={handleRemove(rest.businessId)}
                          disabled={!hasL3Access}
                          variant="plain"
                        />
                      </DropdownContainer>
                    </DataGridContainer.Cell>
                  </DataGridContainer.Row>
                )
              })
            : undefined}
        </DataGridContainer.Body>
      </DataGridContainer>

      {!!needsPagination && (
        <Container padding="none" justifyContent="center" flexDirection="row">
          <Pagination
            previousLabel={previousLabel}
            nextLabel={nextLabel}
            pageCount={pageCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      )}

      <InvitationModal
        businessId={organizationId}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </Container>
  )
}

export default UserOrganizations
