import { FunctionComponent } from 'react'
import { ReactI18NextChild } from 'react-i18next'

import { CenteredLayout } from '../../../../../components/CenteredLayout/CenteredLayout.component'
import { WeakIdentificationStepper } from '../WeakIdentificationStepper/WeakIdentificationStepper.component'

type Props = {
  children: ReactI18NextChild
  currentStep: number
  numberOfSteps: number
  hideSteps: boolean
}

const WeakIdentificationLayoutWrapper: FunctionComponent<Props> = (props: Props) => {
  const { children, currentStep, numberOfSteps, hideSteps } = props

  return (
    <>
      {!hideSteps && (
        <WeakIdentificationStepper currentStep={currentStep} numberOfSteps={numberOfSteps} />
      )}
      <CenteredLayout>{children}</CenteredLayout>
    </>
  )
}

export { WeakIdentificationLayoutWrapper }
