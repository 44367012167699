/* eslint-disable react/display-name */

/* eslint-disable react/function-component-definition */
import { useQuery } from '@tanstack/react-query'
import { ComponentType, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import tokens from '../api/tokens'
import { QUERY_KEY_AUTH_START_L3, SESSION_KEY_REDIRECT } from '../constants'
import StrongAuthRequired from '../containers/StrongAuthRequired'
import { selectAuthLevel } from '../redux/slices/meSlice'
import { ACRValues } from '../routes/Login/Authenticate/Authenticate.schema'

const withL3Access = <P extends object>(WrappedComponent: ComponentType<P>) => {
  return (props: P) => {
    const authLevel = useSelector(selectAuthLevel)
    const location = useLocation()
    const [searchParams] = useSearchParams()

    const { data, status, refetch } = useQuery(
      [QUERY_KEY_AUTH_START_L3],
      () => tokens.initiateAuthProcess(ACRValues.ESuomi, 'login'),
      {
        enabled: false,
      }
    )

    useEffect(() => {
      if (status === 'success' && !!data) {
        // Set redirection url for /sso redirect
        sessionStorage.setItem(
          SESSION_KEY_REDIRECT,
          `${location.pathname}?${searchParams.toString()}`
        )
        window.location.assign(data.authRedirUrl)
      }
    }, [data, location.pathname, searchParams, status])

    if (!authLevel) {
      return null
    }

    const handleStartStrongAuth = useCallback(() => {
      refetch()
    }, [refetch])

    if (authLevel !== 'L3') {
      return <StrongAuthRequired onStartAuth={handleStartStrongAuth} />
    }

    return <WrappedComponent {...props} />
  }
}

export default withL3Access
