/* eslint-disable react/display-name */

/* eslint-disable react/function-component-definition */
import { ComponentType } from 'react'
import { Navigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import useAuth from '../hooks/useAuth'

const withAuth = <P extends object>(WrappedComponent: ComponentType<P>) => {
  return (props: P) => {
    const { authenticated, isLoading } = useAuth()
    const [searchParams] = useSearchParams()

    const fallbackRoute = `/?${searchParams.toString()}`

    if (isLoading) return null

    if (!authenticated) return <Navigate to={fallbackRoute} />

    return <WrappedComponent {...props} />
  }
}

export default withAuth
