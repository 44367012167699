import { z } from 'zod'

export enum ACRValues {
  Tupas = 'tupas',
  Cidlog = 'cidlog',
  ESuomi = 'esuomi',
  EmailConfirmation = 'emailconfirmation',
}

export enum AuthenticationLevels {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
}

export const ACRValueValidation = z.nativeEnum(ACRValues)
