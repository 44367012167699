import { Children, FunctionComponent, createElement, isValidElement } from 'react'
import styled from 'styled-components'

import { Spacing } from '../../shared/enums'
import ErrorMessage from '../ErrorMessage'
import FieldLabel from '../FieldLabel'
import RadioButton from '../Radio/Radio'
import IRadioButton from '../Radio/Radio.interface'
import IRadioButtonGroup from './RadioGroup.interface'

const StyledFieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const RadioButtonGroup: FunctionComponent<IRadioButtonGroup> = ({
  name,
  label,
  children,
  hasError,
  errorMessage,
}) => {
  return (
    <StyledFieldSet name={name}>
      <FieldLabel as="legend" style={{ marginBottom: Spacing.Small }}>
        {label}
      </FieldLabel>
      {Children.map(children, child => {
        return isValidElement<IRadioButton>(child)
          ? createElement(RadioButton, {
              ...child.props,
            })
          : undefined
      })}
      {hasError && !!errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : undefined}
    </StyledFieldSet>
  )
}

export default RadioButtonGroup
