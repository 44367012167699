import HttpError from '../errors/HttpError'

const startEmailVerification = async (email: string) => {
  const response = await fetch('/api/verifications/email', {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const data = await response.json()

  if (response.status === 409) throw new HttpError(409, data.details?.email)

  if (response.status === 422) throw new HttpError(422, data.errors[0].message)

  return data
}

const processVerificationResponse = async (obj: { reference: string; token: string }) => {
  const response = await fetch('/api/verifications/email', {
    method: 'PUT',
    body: JSON.stringify(obj),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return await response.json()
}

export default {
  startEmailVerification,
  processVerificationResponse,
}
