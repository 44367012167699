import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'

import tokens from '../api/tokens'
import { ENVIRONMENT, QUERY_KEY_AUTH_CHECK } from '../constants'
import { setAuthLevel, setUid } from '../redux/slices/meSlice'
import { AuthLevel } from '../shared/types'

const useAuth = () => {
  try {
    const dispatch = useDispatch()
    const { data, status } = useQuery([QUERY_KEY_AUTH_CHECK], tokens.getUserInfo, {
      retry: false,
    })

    if (status === 'loading') {
      return {
        isLoading: true,
        authenticated: false,
      }
    }

    if (status === 'error') {
      return { isLoading: false, authenticated: false }
    }

    if (data === undefined) throw new Error('Unauthorized')

    let authLevel: AuthLevel = 'L2'

    if (data.value.acr === 'esuomi') authLevel = 'L3'

    if ((ENVIRONMENT === 'development' || ENVIRONMENT === 'test') && data.value.acr === 'tupas')
      authLevel = 'L3'

    dispatch(setAuthLevel(authLevel))

    if (data.value.uid) dispatch(setUid(data.value.uid[0]))

    return { isLoading: false, authenticated: true }
  } catch (error) {
    console.error(error)

    return { isLoading: false, authenticated: false }
  }
}

export default useAuth
