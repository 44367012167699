/* eslint-disable react/jsx-no-bind */
import PiwikPro from '@piwikpro/react-piwik-pro'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import Snackbar from './components/Snackbar'
import Layout from './components/layout'
import { PIWIK_CONTAINER_ID, PIWIK_CONTAINER_URL } from './constants'
import './i18n'
import './index.css'
import store from './redux/store'
import reportWebVitals from './reportWebVitals'
import router from './routes/router'
import theme from './theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

PiwikPro.initialize(PIWIK_CONTAINER_ID, PIWIK_CONTAINER_URL)

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Snackbar />
      <QueryClientProvider client={queryClient}>
        <Layout>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </Layout>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
