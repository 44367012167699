import { IOrganizationDetails, IRequest, IUserInformation } from '../shared/interfaces'
import { FormValues } from '../shared/types'
import {
  formatChangeEmailFormValues,
  formatChangePasswordFormValues,
  formatChangeUserBasicsFormValue,
} from '../utils/form-utils'

export enum MeKeys {
  UpdateInfo = 'UPDATE_ME_USER_INFO',
}

const getMyUserInformation = async (): Promise<IUserInformation | undefined> => {
  const response = await fetch('/api/me')
  const data = await response.json()

  return data.value
}

const getMyOrganizations = async (): Promise<Array<IOrganizationDetails> | undefined> => {
  const response = await fetch('/api/me/organizations')
  const data = await response.json()

  return data.value
}

const leaveOrganization = async (businessId: string) => {
  const response = await fetch(`/api/me/organizations/${businessId}`, {
    method: 'DELETE',
  })

  return await response.json()
}

const getMyRequests = async (): Promise<Array<IRequest> | undefined> => {
  const response = await fetch('/api/me/requests')
  const data = await response.json()

  return data.value
}

const getMyReceivedRequests = async (): Promise<Array<IRequest> | undefined> => {
  const response = await fetch('/api/me/received-requests')
  const data = await response.json()

  return data.value
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cancelMyRequest = async (requestId: string): Promise<any | undefined> => {
  const response = await fetch(`/api/me/requests/${requestId}`, {
    method: 'DELETE',
  })

  return await response.json()
}

const updateUserInfoConfig = {
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
}

const changePassword = async (formValues: FormValues) => {
  const formattedValues = formatChangePasswordFormValues(formValues)
  const response = await fetch('/api/me/password', {
    ...updateUserInfoConfig,
    body: JSON.stringify(formattedValues),
  })

  return response.json()
}

const updateBasicUserInfo = async (formValues: FormValues) => {
  const formattedValues = formatChangeUserBasicsFormValue(formValues)
  const response = await fetch('/api/me/basics', {
    ...updateUserInfoConfig,
    body: JSON.stringify(formattedValues),
  })

  return response.json()
}

const changeEmail = async (formValues: FormValues) => {
  const formattedValues = formatChangeEmailFormValues(formValues)
  const response = await fetch('/api/me/email', {
    ...updateUserInfoConfig,
    body: JSON.stringify(formattedValues),
  })

  return response.json()
}

const updateLastLogin = async () => {
  const response = await fetch('/api/me/lastlogin', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.json()
}

const amIEnabled = async () => {
  const response = await fetch('/api/me/status')

  return await response.json()
}

export default {
  getMyUserInformation,
  getMyOrganizations,
  leaveOrganization,
  getMyRequests,
  getMyReceivedRequests,
  cancelMyRequest,
  changePassword,
  changeEmail,
  updateBasicUserInfo,
  updateLastLogin,
  amIEnabled,
}
