import { useQuery } from '@tanstack/react-query'

import invitations from '../api/invitations'
import { QUERY_KEY_GET_INVITATION } from '../constants'
import { IInvitation } from '../shared/interfaces'

const useInvitation = (
  invitationId: string | null
): { isLoading: boolean; invitation: IInvitation | undefined } => {
  if (!invitationId) return { isLoading: false, invitation: undefined }

  try {
    const { data, status, refetch } = useQuery(
      [QUERY_KEY_GET_INVITATION],
      () => invitations.getReceivedInvitationById(invitationId),
      {
        enabled: false,
      }
    )

    refetch()

    if (status === 'loading') return { isLoading: true, invitation: undefined }

    if (status === 'error' || data === undefined) throw new Error('Could not fetch service')

    return { isLoading: false, invitation: data }
  } catch {
    return { isLoading: false, invitation: undefined }
  }
}

export default useInvitation
