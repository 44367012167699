import { FormValues } from '../shared/types'
import { formatRegistrationFormValues } from '../utils/form-utils'

const createUser = async (formValues: FormValues) => {
  const formattedValues = formatRegistrationFormValues(formValues)

  const response = await fetch('/api/users', {
    method: 'POST',
    body: JSON.stringify(formattedValues),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  //Not validation error
  if (!response.ok && response.status !== 422) {
    throw new Error(response.statusText)
  }

  return response.json()
}

export default {
  createUser,
}
