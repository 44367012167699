import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import me, { MeKeys } from '../../../../api/me'
import Button from '../../../../components/Button/Button'
import CenteredLoadingIndicator from '../../../../components/CenteredLoadingIndicator/CenteredLoadingIndicator.component'
import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import ReactFormContainer from '../../../../components/ReactFormWrapper/ReactFormWrapper.component'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import { QUERY_KEY_USER_PROFILE } from '../../../../constants'
import useL3Access from '../../../../hooks/useL3Access'
import { setSpinner } from '../../../../redux/slices/spinnerSlice'
import { IUserInformation } from '../../../../shared/interfaces'
import { BasicInformation, BasicInformationValidation } from './ChangeBasicInformation.schema'

type Props = {
  data: IUserInformation | undefined
}

const ERROR_MESSAGE_CLASS_NAME = 'error-message'

const ChangeBasicInformationForm: FunctionComponent<Props> = ({ data }) => {
  const { t } = useTranslation()
  const hasL3Access = useL3Access()
  const dispatch = useDispatch()

  const {
    register,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm<BasicInformation>({
    resolver: zodResolver(BasicInformationValidation),
    defaultValues: {
      firstname: data?.firstname ?? '',
      lastname: data?.surname ?? '',
      phone: data?.mobile ?? '+358',
      hetu: data?.hetu ?? '',
    },
    mode: 'onChange',
  })

  const changeBasicsMutation = useMutation({
    mutationFn: me.updateBasicUserInfo,
    mutationKey: [MeKeys.UpdateInfo],
  })

  const queryClient = useQueryClient()

  const handleSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_USER_PROFILE] })
    dispatch(openSnackbar(t('snackbar.basic-info-success'), false))
  }

  useEffect(() => {
    if (!data) {
      return
    }

    setValue('firstname', data.firstname)
    setValue('lastname', data.surname)

    if (data?.mobile?.includes('+')) {
      setValue('phone', data.mobile)
    }

    setValue('hetu', data.hetu)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    // TODO: Server errors
    if (changeBasicsMutation.status === 'success' && changeBasicsMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))
    } else if (changeBasicsMutation.status === 'success' && !changeBasicsMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))
      handleSuccess()
    } else if (changeBasicsMutation.status === 'error') {
      dispatch(setSpinner({ visible: false }))
      dispatch(openSnackbar(t('snackbar.basic-info-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeBasicsMutation.data, changeBasicsMutation.status])

  const onSubmit = async (values: BasicInformation) => {
    const validation = BasicInformationValidation.safeParse(values)

    if (!validation.success) {
      return console.log('Validation failed:', validation.error)
    }

    dispatch(setSpinner({ visible: true }))
    changeBasicsMutation.mutate(values)
  }

  return (
    <ReactFormContainer onSubmit={handleSubmit(onSubmit)}>
      <Container padding={'none'} margin={'none none small none'}>
        <Heading level="h2" visualLevel="h5" color="bf-blue">
          {t('user-profile.basic-information')}
        </Heading>
      </Container>
      {!data && <CenteredLoadingIndicator />}
      {data ? (
        <>
          <label htmlFor={'firstname'}>{t('microcopies.form-label-firstname')}</label>
          <input type="text" {...register('firstname')} id={'firstname'} readOnly={!hasL3Access} />
          {errors.firstname ? (
            <span className={ERROR_MESSAGE_CLASS_NAME}>{t('user-profile.invalid-firstname')}</span>
          ) : null}
          <label htmlFor={'lastname'}>{t('microcopies.form-label-lastname')}</label>
          <input type="text" {...register('lastname')} id={'lastname'} readOnly={!hasL3Access} />
          {errors.lastname ? (
            <span className={ERROR_MESSAGE_CLASS_NAME}>{t('user-profile.invalid-lastname')}</span>
          ) : null}
          {watch('hetu') && (
            <>
              <label htmlFor={'hetu'}>{t('microcopies.form-label-ssn')}</label>
              <input type="text" {...register('hetu')} id={'hetu'} readOnly />
            </>
          )}
          <label htmlFor={'phone'}>{t('microcopies.form-label-phone')}</label>
          <input type="tel" {...register('phone')} />
          <span className={'help-text'}>{t('account-creation.form-phone-helptext')}</span>
          {errors.phone ? (
            <span className={ERROR_MESSAGE_CLASS_NAME}>{t('user-profile.invalid-phone')}</span>
          ) : null}
          {hasL3Access ? (
            <Button
              disabled={Object.keys(errors).length > 0}
              type="submit"
              label={t('user-profile.basic-information-save')}
            />
          ) : null}
        </>
      ) : null}
    </ReactFormContainer>
  )
}

export default ChangeBasicInformationForm
