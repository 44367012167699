// TODO: Add proper implementation! This returns mock value.
import { useTranslation } from 'react-i18next'

import { AuthLevel } from '../shared/types'

// Maybe return the value from an API?
export const getAuthLevel = (referrer: string) => {
  if (referrer === '') return 2

  return 3
}

// TODO: Add proper implementation! This returns mock value.
// Maybe return the value from an API?
export const getTargetServiceName = (referrer: string) => {
  const { t: translate } = useTranslation()

  if (referrer === '') return translate('microcopies.e-service')

  return '' // Return a value from an API here
}

export const authLevelToAcr = (authLevel: AuthLevel) => {
  switch (authLevel) {
    case 'L1':
      return 'tupas'

    case 'L2':
      return 'cidlog'

    case 'L3':
      return 'esuomi'
  }
}
