import { configureStore } from '@reduxjs/toolkit'

import meReducer from './slices/meSlice'
import organizationSearchReducer from './slices/organizationSearchSlice'
import snackbarReducer from './slices/snackbarSlice'
import spinnerReducer from './slices/spinnerSlice'

const store = configureStore({
  reducer: {
    me: meReducer,
    organizationSearch: organizationSearchReducer,
    snackbar: snackbarReducer,
    spinner: spinnerReducer,
  },
  devTools: true,
})

store.subscribe(() => {
  // Subscribe to state changes...
})

export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type
export type AppDispatch = typeof store.dispatch
