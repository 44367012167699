import { useQueryClient } from '@tanstack/react-query'
import { changeLanguage, t } from 'i18next'
import { FunctionComponent, MouseEventHandler, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import Header from '../../components/Header'
import { QUERY_KEY_SERVICE_INFORMATION } from '../../constants'
import useLanguageItems from '../../hooks/useLanguageItems'
import useReturnButton from '../../hooks/useReturnButton'
import { SUPPORTED_LOCALES } from '../../i18n'
import { selectAuthLevel } from '../../redux/slices/meSlice'
import logoutAndRedirect from '../../utils/logout-utils'
import { validateServiceReturnUrl } from '../../utils/validation-utils'
import IHeaderContainer from './HeaderContainer.interface'

/**
 * Container for Header
 * @prop `variation` Header variation
 * @returns Presentational Header component
 */
const HeaderContainer: FunctionComponent<IHeaderContainer> = props => {
  const [searchParams, setSearchParams] = useSearchParams()
  const authLevel = useSelector(selectAuthLevel)
  const queryClient = useQueryClient()
  const returnButton = props.showReturnButton === false ? undefined : useReturnButton()

  const languageItems = useLanguageItems()

  const returnButtonLabel = t('microcopies.back')

  /** Language change event handler */
  const handleLanguageChange: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      const target = event.target as HTMLButtonElement

      /** Change language and update query params */
      if (SUPPORTED_LOCALES.includes(target.value))
        changeLanguage(target.value, error => {
          if (error) console.error(error)

          // Refetch language specific queries
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_SERVICE_INFORMATION],
          })

          /** Update search params */
          setSearchParams({ ...Object.fromEntries(searchParams), lang: target.value })
          // TODO: If default locale, clear lang from params
          // TODO: This causes a re-render. Create an utility function for updating
          // query params without re-rendering
        })
    },
    [queryClient, searchParams, setSearchParams]
  )

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleLogout = () => {
    logoutAndRedirect()
  }

  const handleReturnButton = async () => {
    if (returnButton) {
      try {
        const validatedReturnUrl = await validateServiceReturnUrl(
          returnButton.service,
          returnButton.href
        )
        window.location.assign(validatedReturnUrl)
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <Header
      logoAriaLabel={t('microcopies.logo-aria-label') || undefined}
      languageItems={languageItems}
      languageItemsAriaLabel={t('microcopies.select-language') || undefined}
      handleLogout={handleLogout}
      onLanguageChange={handleLanguageChange}
      showLogout={!!authLevel || props.hasL1Session}
      showReturnButton={!!returnButton}
      handleReturnButton={handleReturnButton}
      returnButtonLabel={returnButtonLabel}
      logoutButtonAriaLabel={t('microcopies.logout') || undefined}
    />
  )
}

export default HeaderContainer
