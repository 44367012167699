import { ColorProp } from '../../shared/types'

export type LogoSize = 'medium' | 'large'

export enum LogoWidth {
  Medium = '7.937rem',
  Large = '10.25rem',
}

export enum LogoHeight {
  Medium = '3.375rem',
  Large = '4.375rem',
}

export default interface ILogo {
  /** Logo color */
  color?: ColorProp
  /** Logo size */
  size?: LogoSize
  /** Aria label */
  ariaLabel?: string
}
