import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  FunctionComponent,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import invitations from '../../../../api/invitations'
import meApi from '../../../../api/me'
import Button from '../../../../components/Button'
import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import Link from '../../../../components/Link'
import Pagination from '../../../../components/Pagination'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import Text from '../../../../components/Text'
import {
  QUERY_KEY_CANCEL_REQUEST,
  QUERY_KEY_MY_INVITATIONS,
  QUERY_KEY_MY_REQUESTS,
} from '../../../../constants'
import DataGridContainer from '../../../../containers/DataGridContainer'
import useL3Access from '../../../../hooks/useL3Access'
import usePagination from '../../../../hooks/usePagination'
import { NotificationType } from '../../../../shared/enums'
import { INotification } from '../../../../shared/types'
import { isInvitation } from '../../../../utils/notification-utils'
import { NotificationRow } from '../Notifications.interface'

const Sent: FunctionComponent = () => {
  const { t: translate } = useTranslation()
  const dispatch = useDispatch()
  const hasL3Access = useL3Access()

  const [notifications, setNotifications] = useState<Array<INotification>>()
  const [isLoading, setIsLoading] = useState(true)

  const queryClient = useQueryClient()
  const myRequestsQuery = useQuery([QUERY_KEY_MY_REQUESTS], meApi.getMyRequests)
  const myInvitationsQuery = useQuery([QUERY_KEY_MY_INVITATIONS], invitations.getSentInvitations)
  const cancelRequestMutation = useMutation({
    mutationKey: [QUERY_KEY_CANCEL_REQUEST],
    mutationFn: (requestId: string) => meApi.cancelMyRequest(requestId),
  })

  const cancelInvitationMutation = useMutation({
    mutationKey: [],
    mutationFn: (requestId: string) => invitations.cancelInvitation(requestId),
  })

  useEffect(() => {
    if (myRequestsQuery.status === 'success' && myInvitationsQuery.status === 'success') {
      const combinedNotifications = []

      if (myRequestsQuery.data) combinedNotifications.push(...myRequestsQuery.data)

      if (myInvitationsQuery.data) combinedNotifications.push(...myInvitationsQuery.data)

      setNotifications(combinedNotifications)
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [
    myInvitationsQuery.data,
    myInvitationsQuery.status,
    myRequestsQuery.data,
    myRequestsQuery.status,
    setNotifications,
  ])

  useEffect(() => {
    if (cancelRequestMutation.status === 'success') {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_MY_REQUESTS],
      })
      dispatch(openSnackbar(translate('snackbar.cancel-request-success'), false))
    } else if (cancelRequestMutation.status === 'error') {
      dispatch(openSnackbar(translate('snackbar.cancel-request-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelRequestMutation.status, queryClient])

  useEffect(() => {
    if (cancelInvitationMutation.status === 'success') {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_MY_INVITATIONS],
      })
      dispatch(openSnackbar(translate('snackbar.cancel-invitation-success'), false))
    } else if (cancelInvitationMutation.status === 'error') {
      dispatch(openSnackbar(translate('snackbar.cancel-invitation-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelInvitationMutation.status, queryClient])

  const handleCancelRequest = useCallback(
    (requestId: string) => (_event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      cancelRequestMutation.mutate(requestId)
    },
    [cancelRequestMutation]
  )

  const handleCancelInvitation = useCallback(
    (requestId: string) => (_event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      cancelInvitationMutation.mutate(requestId)
    },
    [cancelInvitationMutation]
  )

  const notificationColumns = [
    { key: 'notifications', name: translate('microcopies.notifications') },
    { key: 'actions', name: translate('microcopies.actions') },
  ]

  const notificationRows = useMemo(() => {
    if (!isLoading && notifications) {
      return notifications.reduce((acc: Array<NotificationRow>, current: INotification) => {
        const tKey = isInvitation(current) ? 'invitations.my-invitation' : 'requests.my-request'
        let user

        if (isInvitation(current)) {
          user = current.recipient.user
            ? `${current.recipient.user.firstname} ${current.recipient.user.surname}`
            : current.recipient.email
        }

        const rowData = {
          id: current.id,
          type: isInvitation(current) ? NotificationType.Invitation : NotificationType.Request,
          text: (
            <Trans
              i18nKey={tKey}
              t={translate}
              components={{
                link1: <Text weight="bold">{user}</Text>,
                link2: (
                  <Link href={`/portal/organization/${current.organization.businessId}`}>
                    {current.organization.name}
                  </Link>
                ),
              }}
              tOptions={{
                user,
                organization: current.organization.name,
              }}
            />
          ),
        }

        acc.push(rowData)

        return acc
      }, [])
    }

    return []
  }, [isLoading, notifications, translate])

  const previousLabel = translate('microcopies.previous')
  const nextLabel = translate('microcopies.next')

  const { needsPagination, currentItems, pageCount, currentPage, setCurrentPage } = usePagination(
    notificationRows || []
  )

  return (
    <Container gap="small">
      <Heading level="h2" visualLevel="h5" color="bf-blue">
        {translate('requests.my-requests-title')}
      </Heading>

      {!notifications && <Text>{translate('notifications.not-found')}</Text>}

      <DataGridContainer>
        <DataGridContainer.Header>
          {notificationColumns
            ? notificationColumns.map(col => {
                return <DataGridContainer.Column key={col.name} name={col.name} />
              })
            : undefined}
        </DataGridContainer.Header>
        <DataGridContainer.Body>
          {currentItems
            ? currentItems.map((row: NotificationRow, rowIndex: number) => {
                const { id, type, ...rest } = row

                const handleCancel =
                  type === NotificationType.Invitation
                    ? handleCancelInvitation
                    : handleCancelRequest

                return (
                  <DataGridContainer.Row key={rowIndex}>
                    <>
                      {Object.values(rest).map((cell: ReactNode, cellIndex: number) => {
                        return (
                          <DataGridContainer.Cell key={cellIndex}>{cell}</DataGridContainer.Cell>
                        )
                      })}
                    </>
                    <DataGridContainer.Cell>
                      <Button
                        label={translate('microcopies.cancel')}
                        variant="negative"
                        onClick={handleCancel(id)}
                        disabled={!hasL3Access}
                      />
                    </DataGridContainer.Cell>
                  </DataGridContainer.Row>
                )
              })
            : undefined}
        </DataGridContainer.Body>
      </DataGridContainer>

      {!!needsPagination && (
        <Container padding="none" justifyContent="center" flexDirection="row">
          <Pagination
            previousLabel={previousLabel}
            nextLabel={nextLabel}
            pageCount={pageCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      )}
    </Container>
  )
}

export default Sent
