/* eslint-disable import/no-named-as-default-member */
import i18n, { Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationEN from './locales/en/translation.json'
import translationFI from './locales/fi/translation.json'
import translationSV from './locales/sv/translation.json'

/** Translation resources */
const resources: Resource = {
  fi: {
    translation: translationFI,
  },
  en: {
    translation: translationEN,
  },
  sv: {
    translation: translationSV,
  },
}

/**
 * Language detextion options
 *
 * https://github.com/i18next/i18next-browser-languageDetector
 */
const detection = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],
  // keys or params to lookup language from
  lookupQuerystring: 'lang',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  // cache user language on
  caches: ['localStorage', 'cookie'],
}

export const DEFAULT_LOCALE = 'fi'
export const SUPPORTED_LOCALES = Object.keys(resources)

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: DEFAULT_LOCALE,
    supportedLngs: SUPPORTED_LOCALES,
    interpolation: {
      escapeValue: false,
    },
    resources,
    detection,
  })
