import { useSelector } from 'react-redux'

import { RootState } from '../redux/store'

/**
 * Check whether the logged in user has L3 access
 *
 * @example
 * ```
 * const hasL3Access = useL3Access()
 * ```
 */
const useL3Access = () => {
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const authLevel = useSelector((state: RootState) => state.me.authLevel)

  return authLevel === 'L3'
}

export default useL3Access
