import styled from 'styled-components'

import { Heading5 } from '../../../../components/Heading/Heading.style'
import { ColorEnum, FontWeight } from '../../../../shared/enums'

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;

  @media (min-width: 365px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const StyledInputContainer = styled.div`
  margin-bottom: 1rem;
`

export const StyledParagraph16 = styled.p`
  margin-bottom: 1rem;
`

export const StyledTitle5 = styled(Heading5)`
  text-transform: unset;
  color: ${ColorEnum.black};
`

export const StyledTextlink = styled.a`
  color: ${ColorEnum.black};
  font-weight: ${FontWeight.Bold};
  text-decoration: underline;
  cursor: pointer;
`

export const StyledContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`

export const StyledErrorMessage = styled.span`
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  display: flex;
  align-items: center;
  gap: 0.625rem;
`

export const StyledIcon = styled.div`
  border: 2px solid ${ColorEnum.bfRed};
  background: ${ColorEnum.bfPink};
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledSuccessIcon = styled.div`
  border: 2px solid ${ColorEnum.bfGreen};
  background: ${ColorEnum.bfLightGray};
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
