import { FunctionComponent } from 'react'

import { LdsRoller } from '../Spinner/Spinner.style'

type Props = {
  height?: number
}

/**
 * Simple loading indicator
 * @returns {JSX.Element}
 */
export const LoadingIndicator: FunctionComponent<Props> = props => {
  const { height } = props

  return (
    <LdsRoller height={height}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LdsRoller>
  )
}
