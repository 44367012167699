import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { setShowSnackbar } from '../../redux/slices/snackbarSlice'
import { Breakpoint, ColorEnum, Spacing } from '../../shared/enums'
import Button from '../Button/Button'
import Icon from '../Icon'

const SnackbarContainer = styled.div`
  transition: 150ms ease-in-out;
  width: 100%;
  justify-content: center;
  display: flex;

  position: fixed;
  flex-direction: row;
  bottom: 5%;
  right: 50%;
  transform: translate(50%, 50%);
  z-index: 1000000;
`

const SnackbarWrapper = styled.div`
  max-width: ${Breakpoint.ExtraLarge};
  width: 100%;
  margin-bottom: ${Spacing.Medium};
  padding: 0 ${Spacing.ExtraLarge};
  @media (max-width: ${Breakpoint.Large}) {
    width: calc(100vw - (${Spacing.Large} * 2));
    padding: 0 ${Spacing.Large};
  }
`

const StyledSnackbar = styled.div<{ error: boolean }>`
  padding: 12px 8px;
  border: 1px solid;
  border-left: 10px solid;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.12) 2px 2px 10px;
  border-color: ${props => (props.error ? ColorEnum.bfRed : ColorEnum.bfGreen)};
  margin-bottom: 12px;

  &.auto {
    width: auto;
  }
`

const selector = (state: {
  snackbar: { showSnackbar: boolean; message: string; error: boolean }
}) => state.snackbar

const Snackbar = () => {
  const showSnackbar = useSelector(selector)
  const dispatch = useDispatch()
  const { t: translate } = useTranslation()

  const closeSnackbar = () => {
    dispatch(
      setShowSnackbar({
        showSnackbar: false,
        message: '',
        error: false,
      })
    )
  }

  if (!showSnackbar.error) {
    setTimeout(() => {
      closeSnackbar()
    }, 5000)
  }

  const renderIcon = () => {
    return showSnackbar.error ? (
      <Icon name="dangerous" color="bf-red" size="medium" />
    ) : (
      <Icon name="check-circle" color="bf-green" size="medium" />
    )
  }

  return (
    <SnackbarContainer
      style={{
        visibility: showSnackbar.showSnackbar ? 'visible' : 'hidden',
        opacity: showSnackbar.showSnackbar ? '1' : '0',
      }}
    >
      <SnackbarWrapper
        style={{
          visibility: showSnackbar.showSnackbar ? 'visible' : 'hidden',
          scale: showSnackbar.showSnackbar ? '1' : '0.0',
        }}
      >
        <StyledSnackbar error={showSnackbar.error}>
          <ul
            style={{
              color: 'black',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  marginRight: '8px',
                  display: 'flex',
                }}
              >
                {renderIcon()}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <li>{showSnackbar.message}</li>
              </div>
            </div>
            <Button
              iconRight={true}
              icon="close"
              iconSize="smallest"
              label={translate('microcopies.close')}
              onClick={closeSnackbar}
              variant="text-button"
            />
          </ul>
        </StyledSnackbar>
      </SnackbarWrapper>
    </SnackbarContainer>
  )
}

export default Snackbar
