import { z } from 'zod'

export const ChangeEmailFormValidation = z
  .object({
    emailVerification: z.string().email({ message: 'user-profile.invalid-email' }),
    currentEmail: z.string().email(),
  })
  .refine(data => data.emailVerification !== data.currentEmail, {
    path: ['emailVerification'],
    message: 'user-profile.duplicate-email',
  })

export const EmailChangeFormValidation = z.object({
  email: z.string().email({ message: 'user-profile.invalid-email' }),
  verificationKey: z.string(),
})

export type ChangeEmailFormType = z.infer<typeof ChangeEmailFormValidation>
export type EmailChangeFormType = z.infer<typeof EmailChangeFormValidation>
