import { SESSION_KEY_REDIRECT, SESSION_KEY_SERVICE } from '../../../constants'
import { IServiceEntity } from '../../../shared/interfaces'
import { WeakIdentificationUrls } from './WeakIdentification.urls'

/**
 * Sets the redirect url for the weak identication.
 * @param mode
 */
export function setWeakIdentificationRedirectUrl(url?: string): void {
  sessionStorage.setItem(SESSION_KEY_REDIRECT, url || WeakIdentificationUrls.miscInformationStep)
}

/**
 * Gets stored session service id
 * @returns {string | null}
 */
export function getServiceId(): string | null {
  const service = sessionStorage.getItem(SESSION_KEY_SERVICE)

  return service ? JSON.parse(service).id : null
}

/**
 * Gets service
 * @returns {IServiceEntity | null}
 */
export function getService(): IServiceEntity | null {
  try {
    const service = sessionStorage.getItem(SESSION_KEY_SERVICE)

    return service ? JSON.parse(service) : null
  } catch (error) {
    console.error('Error parsing service from session storage', error)

    return null
  }
}
