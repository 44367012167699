import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SUPPORTED_LOCALES } from '../i18n'
import { LanguageItem } from '../shared/types'

/**
 * Get language items for Header component's language menu
 *
 * @returns array of language items
 */
const useLanguageItems = () => {
  const { i18n } = useTranslation()
  const language = i18n.language

  /** Memoize language items */
  const languageItems: LanguageItem[] = useMemo(() => {
    const locales = SUPPORTED_LOCALES
    const items: LanguageItem[] = []
    const currentLocale = language

    const supportsDisplayNames = typeof Intl.DisplayNames === 'function'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let languageNames: any

    if (supportsDisplayNames) {
      languageNames = new Intl.DisplayNames([currentLocale], { type: 'language' })
    }

    for (const locale of locales) {
      let label = locale

      if (supportsDisplayNames) {
        label = languageNames.of(locale.toUpperCase())
      }

      items.push({
        label: label,
        country: locale,
        active: locale === language,
      })
    }

    return items
  }, [language])

  return languageItems
}

export default useLanguageItems
