/* eslint-disable unicorn/new-for-builtins */
import { FunctionComponent, MouseEventHandler, useCallback } from 'react'
import styled from 'styled-components'

import { ColorEnum, Spacing } from '../../shared/enums'
import Icon from '../Icon'
import IPagination from './Pagination.interface'

const StyledPagination = styled.nav`
  width: auto;
  & > ul {
    display: flex;
  }
`

const Item = styled.li`
  &.active {
    & button {
      pointer-events: none;
      border: 2px solid ${ColorEnum.bfBlue};
    }
  }
`

const Button = styled.button`
  border: 2px solid rgba(0, 0, 0, 0);
  padding: ${Spacing.Smallest} ${Spacing.Medium};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: ${Spacing.Small};
  color: ${ColorEnum.bfBlue};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  & svg {
    pointer-events: none;
  }

  &:disabled {
    color: ${ColorEnum.bfGray};
    pointer-events: none;

    & svg {
      fill: ${ColorEnum.bfGray};
    }
  }
`

const Ellipsis = styled.div`
  color: ${ColorEnum.bfDarkGray};
  padding: ${Spacing.Smallest} ${Spacing.Medium};
`

const Pagination: FunctionComponent<IPagination> = ({
  previousLabel = '<',
  nextLabel = '>',
  currentPage,
  pageCount,
  setCurrentPage,
}) => {
  let pageNumbers = []

  /**
   * Page numbered buttons visible on the pagination component
   *
   * -1 will create an ellipsis
   */
  if (pageCount > 5) {
    if (currentPage <= 3) {
      pageNumbers = [1, 2, 3, -1, pageCount]
    } else if (currentPage >= pageCount - 2) {
      pageNumbers = [1, -1, pageCount - 2, pageCount - 1, pageCount]
    } else {
      pageNumbers = [1, -1, currentPage - 1, currentPage, currentPage + 1, -1, pageCount]
    }
  } else {
    // Create an array sequence from 1 to N
    pageNumbers = Array.from({ length: pageCount }, (_, index) => index + 1)
  }

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      const target = event.target as HTMLButtonElement

      const targetPage = Number.parseInt(target.value)

      setCurrentPage && setCurrentPage(targetPage)
    },
    [setCurrentPage]
  )

  const previousPage = Math.max(1, currentPage - 1)
  const nextPage = Math.min(pageCount, currentPage + 1)

  return (
    <StyledPagination className="bfsso-pagination">
      <ul>
        <Item>
          <Button
            value={previousPage}
            onClick={handleClick}
            disabled={previousPage === currentPage}
          >
            <Icon name="arrow-left" size="small" color="bf-blue" />
            {previousLabel}
          </Button>
        </Item>

        {pageNumbers.map((num: number, index: number) => {
          if (num === -1) return <Ellipsis key={`ellipsis-${index}`}>&#8230;</Ellipsis>

          return (
            <Item key={`page-${num}-item-${index}`} className={currentPage === num ? 'active' : ''}>
              <Button value={num} onClick={handleClick} aria-pressed={currentPage === num}>
                {num}
              </Button>
            </Item>
          )
        })}

        <Item>
          <Button value={nextPage} onClick={handleClick} disabled={nextPage === currentPage}>
            {nextLabel}
            <Icon name="arrow-right" size="small" color="bf-blue" />
          </Button>
        </Item>
      </ul>
    </StyledPagination>
  )
}

export default Pagination
