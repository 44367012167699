const getServiceInfo = async (serviceId: string, redirectUrl?: string | null) => {
  let path = `/api/services/${serviceId}`

  if (redirectUrl) path += `?redirectUrl=${redirectUrl}`

  const response = await fetch(path)

  return await response.json()
}

export default { getServiceInfo }
