import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { Breakpoint, ColorEnum, FontSize, FontWeight, Spacing } from '../../shared/enums'
import { LanguageItem } from '../../shared/types'
import Icon from '../Icon'
import Logo from '../Logo'
import { LogoHeight, LogoWidth } from '../Logo/Logo.interface'
import IHeader from './Header.interface'

const Wrapper = styled.header`
  background-color: #002ea2;
  width: 100%;
  height: 8.375rem;
`

const Container = styled.div`
  margin: auto;
  max-width: ${Breakpoint.ExtraLarge};
  height: 8.375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.ExtraLarge};

  @media (max-width: ${Breakpoint.Small}) {
    & svg.bf-logo.large {
      width: ${LogoWidth.Medium} !important;
      height: ${LogoHeight.Medium} !important;
    }
  }
`

const LanguageChanger = styled.div`
  display: flex;
  align-items: center;
`

const LanguageButton = styled.button`
  height: 32px;
  width: 32px;
  line-height: 32px;
  cursor: pointer;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  font-size: ${FontSize.ExtraLarge};
  font-weight: ${FontWeight.Bold};
  padding: 0 ${Spacing.Smallest};
  margin-right: ${Spacing.Small};

  &:last-of-type {
    margin-right: ${Spacing.Large};
  }

  &.active {
    text-decoration: underline;
  }

  &:focus {
    outline: 2px solid white;
  }
`
const LogoutButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  padding: 0 ${Spacing.Medium};
  margin-right: ${Spacing.Small};

  &.active {
    text-decoration: underline;
  }

  &:focus {
    outline: 2px solid white;
  }
`

const LeftItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`

const ReturnButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${ColorEnum.white};
  gap: 0.5rem;
  padding: 1rem;
  cursor: pointer;
`

const Header: FunctionComponent<IHeader> = ({
  className,
  logoAriaLabel,
  languageItems,
  languageItemsAriaLabel,
  logoutButtonAriaLabel,
  handleLogout,
  onLanguageChange,
  showLogout,
  showReturnButton,
  returnButtonLabel,
  returnButtonAriaLabel,
  handleReturnButton,
}) => {
  return (
    <Wrapper className={`${className} bfsso-registration-header`}>
      <Container>
        <LeftItems>
          {showReturnButton ? (
            <ReturnButton onClick={handleReturnButton} aria-label={returnButtonAriaLabel}>
              <Icon name="arrow-left" size="small" color="white" />
              <span>{returnButtonLabel}</span>
            </ReturnButton>
          ) : null}
          <Logo color="white" ariaLabel={logoAriaLabel} size="large" />
        </LeftItems>

        {languageItems ? (
          <LanguageChanger role="group">
            {languageItemsAriaLabel ? (
              <div className="hidden">{languageItemsAriaLabel}</div>
            ) : undefined}
            {languageItems.map((languageItem: LanguageItem) => {
              return (
                <LanguageButton
                  key={languageItem.country}
                  onClick={onLanguageChange}
                  value={languageItem.country}
                  aria-label={languageItem.label}
                  aria-pressed={languageItem.active}
                  className={languageItem.active ? 'active' : undefined}
                >
                  {languageItem.country.toUpperCase()}
                </LanguageButton>
              )
            })}
            <Icon color="white" name="language" />
            {showLogout ? (
              <LogoutButtonContainer onClick={handleLogout} aria-label={logoutButtonAriaLabel}>
                <Icon color="white" name="logout" />
              </LogoutButtonContainer>
            ) : undefined}
          </LanguageChanger>
        ) : undefined}
      </Container>
    </Wrapper>
  )
}

export default Header
