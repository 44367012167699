import { FunctionComponent } from 'react'
import styled from 'styled-components'

import Headline from '../../components/Headline'
import { ColorEnum, Spacing } from '../../shared/enums'
import RouteWrapper from '../RouteWrapper'
import IRegistrationRouteWrapper from './RegistrationRouteWrapper.interface'

const Main = styled.main`
  width: 100%;
  background-color: ${ColorEnum.white};
  color: ${ColorEnum.black};
`

const ContentWrapper = styled.div`
  max-width: 44.625rem;
  margin: auto;
  padding: ${Spacing.ExtraLarge};
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const RegistrationRouteWrapper: FunctionComponent<IRegistrationRouteWrapper> = ({
  title,
  children,
}) => {
  return (
    <RouteWrapper title={title}>
      <Headline heading={title} />
      <Main>
        <ContentWrapper>{children}</ContentWrapper>
      </Main>
    </RouteWrapper>
  )
}

export default RegistrationRouteWrapper
