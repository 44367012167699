import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import me from '../../../../api/me'
import Button from '../../../../components/Button/Button'
import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import ReactFormContainer from '../../../../components/ReactFormWrapper/ReactFormWrapper.component'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import useL3Access from '../../../../hooks/useL3Access'
import { setSpinner } from '../../../../redux/slices/spinnerSlice'
import { FormValues } from '../../../../shared/types'
import { ChangePasswordFormValues, ChangePasswordValidation } from './ChangePassword.schema'

const ERROR_MESSAGE_CLASS_NAME = 'error-message'

const ChangePasswordForm: FunctionComponent = () => {
  const { t } = useTranslation()
  const hasL3Access = useL3Access()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ChangePasswordFormValues>({
    defaultValues: {
      newPassword: '',
      confirm: '',
    },
    resolver: zodResolver(ChangePasswordValidation),
    mode: 'onChange',
  })

  const changePasswordMutation = useMutation({
    mutationFn: (values: FormValues) => me.changePassword(values),
  })

  const handleSuccess = async () => {
    setValue('confirm', '')
    setValue('newPassword', '')
    dispatch(openSnackbar(t('snackbar.password-success'), false))
  }

  useEffect(() => {
    // TODO: Server errors
    if (changePasswordMutation.status === 'success' && changePasswordMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))
    } else if (changePasswordMutation.status === 'success' && !changePasswordMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))
      handleSuccess()
    } else if (changePasswordMutation.status === 'error') {
      dispatch(setSpinner({ visible: false }))
      dispatch(openSnackbar(t('snackbar.password-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordMutation.data, changePasswordMutation.status])

  const onSubmit = async (values: ChangePasswordFormValues) => {
    const validation = ChangePasswordValidation.safeParse(values)

    if (!validation.success) {
      return console.log('Validation failed:', validation.error)
    }

    dispatch(setSpinner({ visible: true }))
    changePasswordMutation.mutate(values)
  }

  return (
    <ReactFormContainer onSubmit={handleSubmit(onSubmit)}>
      <Container padding="none" margin="none none small none">
        <Heading level="h2" visualLevel="h5" color="bf-blue">
          {t('user-profile.change-password')}
        </Heading>
      </Container>
      <label htmlFor="newPassword">{t('user-profile.new-password')}</label>
      <input
        type="password"
        id="newPassword"
        {...register('newPassword')}
        readOnly={!hasL3Access}
      />
      <span className={'help-text'}>{t('account-creation.form-password-specification')}</span>
      {errors.newPassword ? (
        <span className={ERROR_MESSAGE_CLASS_NAME}>{t(errors.newPassword.message as string)}</span>
      ) : null}
      <label htmlFor="confirm">{t('user-profile.confirm-new-password')}</label>
      <input type="password" id="confirm" {...register('confirm')} readOnly={!hasL3Access} />
      {errors.confirm ? (
        <span className={ERROR_MESSAGE_CLASS_NAME}>{t(errors.confirm.message as string)}</span>
      ) : null}
      {hasL3Access ? (
        <Button
          disabled={Object.keys(errors).length > 0}
          type="submit"
          label={t('user-profile.change-password')}
        />
      ) : null}
    </ReactFormContainer>
  )
}

export default ChangePasswordForm
