import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ColorEnum } from '../../shared/enums'

interface Props extends React.HTMLAttributes<HTMLFormElement> {
  children: React.ReactNode[]
}

const StyledFormContainer = styled.form`
  & > label {
    text-transform: uppercase;
    display: block;
    color: ${ColorEnum.bfBlue};
  }
  & > input,
  textarea {
    border: 1px solid ${ColorEnum.bfBlue};
    padding: 0.5rem 1rem;
    width: 100%;
    background-color: ${ColorEnum.white};
    text-transform: none;
    margin-bottom: 0.5rem;
  }
  & > input:read-only,
  textarea:read-only {
    background: ${ColorEnum.bfLightGray} !important;
  }
  .checkbox-row {
    display: flex;
    align-items: center;
  }
  .checkbox-row > input {
    all: revert !important;
    border: 1px solid ${ColorEnum.bfBlue};
    border-radius: 3px;
    min-width: 20px !important;
    min-height: 20px !important;
    width: 20px !important;
    height: 20px !important;
    background-color: ${ColorEnum.bfLightGray};
  }
  .checkbox-row > label {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    color: #002ea2;
  }
  .error-message {
    color: ${ColorEnum.bfRed} !important;
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .checkbox-error-message {
    color: ${ColorEnum.bfRed} !important;
    padding-left: 0.25rem;
    font-weight: bold;
  }
  .success-message {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: ${ColorEnum.bfGreen};
  }
  .help-text {
    font-size: 0.9rem;
    font-weight: 400;
    white-space: pre-line;
    margin-top: calc(-0.5rem + 5px);
    margin-bottom: 1rem;
    display: block;
  }
  .pull-right {
    display: flex;
    justify-content: flex-end;
  }
  .bold {
    font-weight: bold !important;
  }
  select {
    all: revert;
    padding: 0.5rem 3rem 0.5rem 1rem;
    width: 100%;
    background-color: ${ColorEnum.white};
    text-transform: none;
    border-style: solid;
    border-color: ${ColorEnum.bfBlue};
    font-family: 'Finlandica', sans-serif;
    font-size: 1rem;
  }
`

/**
 * ReactFormContainer component
 * @param {Props} props
 * @returns {JSX.Element}
 * @description Remember to use mode "onChange" in useForm hook to avoid unnecessary re-renders. This also causes
 * aggressive validation on every keystroke which has a better user experience.
 */
const ReactFormContainer: FunctionComponent<Props> = (props: Props) => {
  const { children, ...rest } = props

  return (
    <StyledFormContainer className="bfsso-form" {...rest}>
      {children}
    </StyledFormContainer>
  )
}

export default ReactFormContainer
