import { useSearchParams } from 'react-router-dom'

import { getSearchParamsAsObject } from '../../../../utils/url-utils'
import { WeakIdentificationSearchQuery } from '../WeakIdentification.schema'

/**
 * By using searchParams there is a possibility where we try to get into a path
 * that doesn't exist. This causes an empty view to be rendered. We also need
 * currently information about the context of attempted registration. Mode means
 * basically that if the user already exists in Ubisecure or not. If the mode is
 * unSelected and view is not SelectRegistrationMode we're gonna redirect user to the first step
 * @param searchParams
 * @returns {void}
 */
export const useCheckWeakIdentificationRouteValidity = () => {
  const [searchParams] = useSearchParams()
  const paramsAsObject = getSearchParamsAsObject(searchParams)

  const validate = () => {
    return WeakIdentificationSearchQuery.safeParse(paramsAsObject).success
  }

  return { validate, searchParams, paramsAsObject }
}
