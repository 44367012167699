import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ColorEnum } from '../../shared/enums'
import IRadio from './Radio.interface'

const StyledInput = styled.input<{ checked: boolean; disabled: boolean | undefined }>`
  border: 1px solid ${props => (props.disabled ? ColorEnum.bfGray : ColorEnum.bfBlue)};
  min-width: 1.625rem;
  min-height: 1.625rem;
  background-color: ${props => (props.checked ? ColorEnum.bfBlue : ColorEnum.white)};
  border-radius: 50%;
`

const StyledLabel = styled.label`
  display: flex;
  gap: 1rem;
  align-items: start;
`

const Radio: FunctionComponent<IRadio> = ({ label, disabled, isRequired, checked, ...rest }) => {
  return (
    <StyledLabel className="bfsso-radio">
      <StyledInput
        type="radio"
        disabled={disabled}
        required={isRequired}
        checked={checked}
        {...rest}
      />
      {label}
    </StyledLabel>
  )
}

export default Radio
