import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type SnackbarState = {
  showSnackbar: boolean
  message: string
  error: boolean
}

const initialState = {
  showSnackbar: false,
  message: '',
  error: false,
} as SnackbarState

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setShowSnackbar: (
      state: SnackbarState,
      action: PayloadAction<{ showSnackbar: boolean; message: string; error: boolean }>
    ) => {
      state.showSnackbar = action.payload.showSnackbar
      state.message = action.payload.message
      state.error = action.payload.error
    },
  },
})

export const { setShowSnackbar } = snackbarSlice.actions

export default snackbarSlice.reducer
