import { ComponentType } from 'react'
import styled, { StyledComponent } from 'styled-components'

import { HeadingSize } from '../../shared/enums'

export type StyledHeading = StyledComponent<ComponentType, Array<string>>

export const Heading1 = styled.h1`
  font-size: ${HeadingSize.H1};
`

export const Heading2 = styled.h2`
  font-size: ${HeadingSize.H2};
`

export const Heading3 = styled.h3`
  font-size: ${HeadingSize.H3};
`

export const Heading4 = styled.h4`
  font-size: ${HeadingSize.H4};
`

export const Heading5 = styled.h5`
  font-size: ${HeadingSize.H5};
`

export const Heading6 = styled.h6`
  font-size: ${HeadingSize.H6};
`
