import React, { FunctionComponent, HTMLAttributes } from 'react'
import styled from 'styled-components'

import { ColorEnum, Spacing } from '../../shared/enums'

interface Props extends HTMLAttributes<HTMLParagraphElement> {
  children: string | string[] | null | React.ReactNode | React.ReactNode[]
}

const StyledParagraph = styled.p`
  box-size: border-box;
  padding: ${Spacing.Large} 0;
  color: ${ColorEnum.black};
`

const Paragraph: FunctionComponent<Props> = ({ children, ...rest }) => {
  return <StyledParagraph {...rest}>{children}</StyledParagraph>
}

export default Paragraph
