import { TFunction } from 'i18next'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { ColorEnum } from '../../shared/enums'
import { Heading5 } from '../Heading/Heading.style'
import Link from '../Link'

export const StyledContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const StyledTitle5 = styled(Heading5)`
  text-transform: unset;
  color: ${ColorEnum.bfBlue};
`

export const StyledParagraph16 = styled.p`
  margin-bottom: 1rem;
`

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  t: TFunction
  errors: FieldErrors
  checkboxKey: string
}

const TermsAndConditions: React.FC<Props> = props => {
  const { register, t, errors, checkboxKey } = props

  return (
    <StyledContainer>
      <StyledTitle5 style={{ marginBottom: '1rem' }}>
        {t('terms-and-conditions.accept-terms')}
      </StyledTitle5>
      <StyledParagraph16>
        <Trans
          i18nKey="terms-and-conditions.terms-description"
          t={t}
          components={{
            link1: (
              <Link href={`${t('terms-and-conditions.link')}`}>
                {'terms-and-conditions.general'}
              </Link>
            ),
          }}
        />
      </StyledParagraph16>
      <div className="checkbox-row">
        <input id={checkboxKey} type="checkbox" {...register(checkboxKey)} />
        <label style={{ cursor: 'pointer' }} htmlFor={checkboxKey}>
          {t('terms-and-conditions.bf-terms')}
        </label>
        {errors && errors[checkboxKey] ? (
          <span className="checkbox-error-message">
            {t('weak-identification.misc-information-required-checkbox-error')}
          </span>
        ) : null}
      </div>
    </StyledContainer>
  )
}

export default TermsAndConditions
