import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ColorEnum, FontSize, FontWeight } from '../../../shared/enums'
import IStepButton from './StepButton.interface'

const StyledStep = styled.div<{ selected?: boolean }>`
  height: 3rem;
  user-select: none;
  width: 3rem;
  background-color: ${ColorEnum.bfLightBlue};
  border: 0.2rem solid ${ColorEnum.bfBlue};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: ${ColorEnum.bfBlue};
    font-weight: ${FontWeight.Bold};
    font-size: ${FontSize.Large};
    margin-bottom: 0;
  }

  &.selected {
    background-color: ${ColorEnum.bfBlue};

    span {
      color: ${ColorEnum.white};
    }
  }

  &.disabled {
    background-color: ${ColorEnum.bfLightGray};
    border-color: #8c8c8c;

    span {
      color: #616161;
    }
  }
`

const StepButton: FunctionComponent<IStepButton> = ({ active, disabled, index }) => {
  // eslint-disable-next-line no-nested-ternary
  const state = active ? 'selected' : disabled ? 'disabled' : ''

  return (
    <StyledStep className={`bfsso-step-button ${state}`}>
      <span>{index}</span>
    </StyledStep>
  )
}

export default StepButton
