import { AnchorHTMLAttributes, FunctionComponent } from 'react'
import styled from 'styled-components'

import { Spacing } from '../../../shared/enums'
import { ColorProp } from '../../../shared/types'
import Icon from '../../Icon'

const StyledLink = styled.a<AnchorHTMLAttributes<HTMLAnchorElement>>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  svg {
    margin-left: ${Spacing.Smallest};
  }
`

const ExternalLink: FunctionComponent<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  href,
  className,
  children,
  color = 'bf-blue',
  ...rest
}) => {
  return (
    <StyledLink
      className={`bfsso-external-link ${className}`}
      href={href}
      target="_blank"
      rel="noreferrer"
      {...rest}
    >
      {children} <Icon name="external-bold" size="smallest" color={color as ColorProp} />
    </StyledLink>
  )
}

export default ExternalLink
