import { useQuery } from '@tanstack/react-query'
import { FunctionComponent } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import HeaderContainer from '../../containers/HeaderContainer'
import { WeakIdentificationRequestKeys } from './WeakIdentification/WeakIdentification.requests'

/**
 * **Registration route**
 *
 * @returns nested registration routes
 */
const Register: FunctionComponent = () => {
  const location = useLocation()

  let showReturnButton = false

  if (location.pathname === '/register') showReturnButton = true

  const hasL1SessionQuery = useQuery({
    queryKey: [WeakIdentificationRequestKeys.session],
    enabled: location.pathname.includes('weak-identification'),
  })

  return (
    <>
      <HeaderContainer
        hasL1Session={Boolean(hasL1SessionQuery?.data)}
        showReturnButton={showReturnButton}
      />
      <Outlet />
    </>
  )
}

export default Register
