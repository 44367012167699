import {
  Children,
  FunctionComponent,
  MouseEventHandler,
  MouseEvent as ReactMouseEvent,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import Dropdown from '../../components/Dropdown'
import IDropdownContainer from './DropdownContainer.interface'

const DropdownContainer: FunctionComponent<IDropdownContainer> = ({ children, ...rest }) => {
  const [open, setOpen] = useState(false)

  const menuRef = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOutsideClick = useCallback((event: any) => {
    if (!menuRef.current?.contains(event.target)) {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    if (open) {
      window.addEventListener('click', handleOutsideClick)
    }

    return () => window.removeEventListener('click', handleOutsideClick)
  }, [handleOutsideClick, open])

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    event.preventDefault()
    console.log(event)
    setOpen(oldState => !oldState)
  }, [])

  /** Inject the dropdown closing function to the item onclick event handler */
  const handleItemClick = useCallback(
    (originalEventHandler: MouseEventHandler<HTMLButtonElement>) => {
      return (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
        originalEventHandler(event)
        setOpen(false)
      }
    },
    []
  )

  return (
    <Dropdown isOpen={open} onClick={handleClick} {...rest} ref={menuRef}>
      {Children.map(children, child =>
        cloneElement(child, {
          ...child.props,
          onClick: child.props.onClick ? handleItemClick(child.props.onClick) : undefined,
        })
      )}
    </Dropdown>
  )
}

export default DropdownContainer
