import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { Breakpoint, ColorEnum, FontWeight, Spacing } from '../../shared/enums'
import Heading from '../Heading'
import IHeadline from './Headline.interface'

const Container = styled.div``

const Wrapper = styled.div`
  background-color: ${ColorEnum.bfLightGray};
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 4.25rem;
  @media (max-width: ${Breakpoint.Small}) {
    min-height: 3.875rem;
  }

  & ${Container} {
    padding: 0 ${Spacing.ExtraLarge};
    margin: auto;
    width: 100%;
    max-width: 44.625rem;
  }

  & .bfsso-heading {
    font-weight: ${FontWeight.Bold};
    color: ${ColorEnum.black};
    font-size: 2rem;
    text-transform: none;
    margin: 0;

    @media (max-width: ${Breakpoint.Small}) {
      font-size: 1.625rem;
    }
  }
`

const Headline: FunctionComponent<IHeadline> = ({ heading, level = 'h1' }) => {
  return (
    <Wrapper>
      <Container>
        <Heading level={level}>{heading}</Heading>
      </Container>
    </Wrapper>
  )
}

export default Headline
