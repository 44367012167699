import { useQuery } from '@tanstack/react-query'
import { FunctionComponent, MouseEventHandler, useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import tokens from '../../../api/tokens'
import Button from '../../../components/Button'
import Container from '../../../components/Container'
import Heading from '../../../components/Heading'
import Link from '../../../components/Link'
import Spacer from '../../../components/Spacer'
import {
  ENVIRONMENT,
  QUERY_KEY_AUTH_START_L3,
  SESSION_KEY_REDIRECT,
  SESSION_KEY_SERVICE,
  SUOMI_FI_LINKS,
} from '../../../constants'
import RegistrationRouteWrapper from '../../../containers/RegistrationRouteWrapper'
import useService from '../../../hooks/useService'
import { ACRValues } from '../../Login/Authenticate/Authenticate.schema'

const StrongIdentification: FunctionComponent = () => {
  const { t: translate, i18n } = useTranslation()
  const [searchParams] = useSearchParams()

  const invitationId = searchParams.get('invitationId')

  const suomiFiLink = useMemo(() => {
    return SUOMI_FI_LINKS[i18n.language]
  }, [i18n.language])

  const { isLoading, service } = useService()

  const { data, status, refetch } = useQuery(
    [QUERY_KEY_AUTH_START_L3],
    () => tokens.initiateAuthProcess(ACRValues.ESuomi),
    {
      enabled: false,
    }
  )

  const handleClick: MouseEventHandler = useCallback(
    _event => {
      refetch()
    },
    [refetch]
  )

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleTupas = async () => {
    const response = await tokens.initiateAuthProcess(ACRValues.Tupas)
    window.location.assign(response?.authRedirUrl)
  }

  useEffect(() => {
    if (status === 'success' && !!data.authRedirUrl) {
      sessionStorage.setItem(SESSION_KEY_REDIRECT, '/register/create-account')

      if (invitationId) sessionStorage.setItem('invitationId', invitationId)

      window.location = data.authRedirUrl
    }
  }, [data, invitationId, status])

  // TODO: Add loader?
  if (isLoading) return null

  // If service is found, set it in session storage
  if (service) {
    sessionStorage.setItem(SESSION_KEY_SERVICE, JSON.stringify(service))
  }

  return (
    <RegistrationRouteWrapper title={translate('strong-identification.title')}>
      <Spacer size="extra-large" />
      <Heading level="h2" visualLevel="h4" color="bf-blue">
        {translate('strong-identification.subtitle')}
      </Heading>
      <div>
        <Trans
          i18nKey="strong-identification.ingress"
          components={{
            link1: <Link href={suomiFiLink}>Suomi.fi</Link>,
          }}
        />
      </div>
      <Container textAlign="center" padding="none" gap="large" alignItems="flex-start">
        <Button label={translate('strong-identification.cta-button-label')} onClick={handleClick} />
        {ENVIRONMENT === 'development' || ENVIRONMENT === 'test' ? (
          <Button
            label={translate('registration-login.tupas-button-label')}
            onClick={handleTupas}
          />
        ) : null}
      </Container>
      <div>
        <Link href="/test">{translate('strong-identification.info-link-label')}</Link>
        <br />
        <Link href="/test">{translate('strong-identification.info-link-label-2')}</Link>
      </div>
    </RegistrationRouteWrapper>
  )
}

export default StrongIdentification
